import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState, useRecoilState, useSetRecoilState } from 'recoil';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getRecoil } from 'recoil-nexus';
import DatePicker from 'react-datepicker';
import { webhooksState } from '../../_states/webhook.states';
import { tr } from '../../common/locale';
import messageUtils from '../../common/messageUtils';
import utils from '../../common/utils';
import Modal from '../../components/Modal';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Flash from '../../components/Flash';
import ConfirmButton from '../../components/ConfirmButton';
import Icon from '../../components/Icon';
import Avatar from '../../components/Avatar';
import Dropdown from '../../components/Dropdown';
import Select from '../../components/Select';
import MoveBoard from '../connections/MoveBoard';
import EmojiPicker from './EmojiPicker';
import GifPicker from './GifPicker';
import Labels from './Labels';
import { templatesState } from '../../_states/template.states';
import avatar from '../../images/avatar.svg';

import {
    lnUserState,
    attachmentsState,
    lnUserConversationsState,
    lnUserUpdateConversationLoadingState,
    lnUserUpdateConnectionLoadingState,
    lnUserConversationState,
    messagesState,
    messageState,
    fileState,
    noteState,
    sendingState,
    loadingNotesState,
    loadingRemindState,
    lnUserSendingMessageState,
    notesState,
    syncLnUserState,
    loadingLnUserState,
    enrichLnUserState,
    lnUserFollowLoadingState,
} from '../../_states/lnuser.states';
import { selectedBoardIdState, connectionsState, boardState } from '../../_states/lnusers.states';
import { searchesOfLeadState, leadSearchState, searchesState, moveLeadSearchState } from '../../_states/leads.states';
import { userState } from '../../_states/user.states';
import useLnUserActions from '../../_actions/lnuser.actions';
import useUserActions from '../../_actions/user.actions';
import useLeadsActions from '../../_actions/leads.actions';
import useLnUsersActions from '../../_actions/lnusers.actions';
import useLinkdInActions from '../../_actions/linkedin.actions';
import useWebhooksActions from '../../_actions/webhook.actions';
import Tooltip from '../../components/Tooltip';
import TooltipPopover from '../../components/TooltipPopover';
import LnUserCustomInfo from './LnUserCustomInfo';

tr('Leads moved from personal network');

function LnUser() {
    const location = useLocation();
    const webhooksActions = useWebhooksActions();
    const modalRef = useRef();
    const invitationRef = useRef();
    const messageInputRef = useRef();
    const dropdownMenuRef = useRef();
    const modalMoveRef = useRef();
    const webhooks = useRecoilValue(webhooksState);
    const user = useRecoilValue(userState);
    const fileRef = useRef();
    const [expand, setExpand] = useState('');
    const [reduceLeft, setReduceLeft] = useState('leftNormal');
    const [reduceRight, setReduceRight] = useState('rightNormal');
    const [invitationText, setInvitationText] = useState('');
    const [lnUser, setLnUser] = useRecoilState(lnUserState);
    const [addNote, setAddNote] = useState(false);
    const [selectedSearch, setSelectedSearch] = useState(null);
    const searches = useRecoilValue(searchesState);
    const loadingNotes = useRecoilValue(loadingNotesState);
    const loadingRemind = useRecoilValue(loadingRemindState);
    const loadingLnUser = useRecoilValue(loadingLnUserState);
    const searchesOfLead = useRecoilValue(searchesOfLeadState);
    const moveLeadSearch = useRecoilValue(moveLeadSearchState);
    const [listName, setListName] = useState('');
    const [remind, setRemind] = useState({ type: null, date: new Date() });

    const leadSearch = useRecoilValue(leadSearchState);
    const lnUserSendingMessage = useRecoilValue(lnUserSendingMessageState);
    const syncLnUser = useRecoilValue(syncLnUserState);
    const sending = useRecoilValue(sendingState);
    const [editNote, setEditNote] = useState(null);
    const [messageTemplate, setMessageTemplate] = useState(null);
    const [attachments, setAttachments] = useRecoilState(attachmentsState);
    const [editNoteContent, setEditNoteContent] = useState('');
    const [selectedWebhook, setSelectedWebhook] = useState(null);
    const setSelectedBoardId = useSetRecoilState(selectedBoardIdState);
    const lnUserConversations = useRecoilValue(lnUserConversationsState);
    const updateConversationLoading = useRecoilValue(lnUserUpdateConversationLoadingState);
    const updateConnectionLoading = useRecoilValue(lnUserUpdateConnectionLoadingState);
    const enrichLnUser = useRecoilValue(enrichLnUserState);
    const resetLnUserConversations = useResetRecoilState(lnUserConversationsState);
    const [conversation, setConversation] = useRecoilState(lnUserConversationState);
    const resetConversation = useResetRecoilState(lnUserConversationState);
    const messages = useRecoilValue(messagesState);
    const templates = useRecoilValue(templatesState);
    const [currentConversation, setCurrentConversation] = useState(null);
    const notes = useRecoilValue(notesState);
    const resetNotes = useResetRecoilState(notesState);
    const [file, setFile] = useRecoilState(fileState);
    const [message, setMessage] = useRecoilState(messageState);
    const resetMessage = useResetRecoilState(messageState);
    const [note, setNote] = useRecoilState(noteState);
    const resetMessages = useResetRecoilState(messagesState);
    const resetLnUser = useResetRecoilState(lnUserState);
    const actions = useLnUserActions();
    const userActions = useUserActions();
    const lnUsersActions = useLnUsersActions();
    const leadsActions = useLeadsActions();
    const linkedInActions = useLinkdInActions();
    const lnUserFollowLoading = useRecoilValue(lnUserFollowLoadingState);

    useEffect(() => {
        if (!webhooks) {
            webhooksActions.get();
        }
    }, []);
    useEffect(() => {
        if (lnUser?.is_lead && !searches) {
            leadsActions.list(true);
        }
    }, [lnUser]);
    useEffect(() => {
        if (lnUser?.conversations_ids?.length > 0) {
            linkedInActions.updateConversation(lnUser.conversations_ids[0], 'read');
        }
    }, [lnUser?.id]);
    useEffect(() => {
        if (lnUser?.id) {
            linkedInActions.getLnUserConversations();
            linkedInActions.updateLnUser();
            actions.get();
            actions.listNotes();
        }
    }, [lnUser?.id]);

    useEffect(() => {
        if (lnUser?.lead.id) {
            leadsActions.getSearchesOfLead(lnUser?.lead.id);
        }
    }, [lnUser?.lead.id, moveLeadSearch]);

    useEffect(() => {
        if (searchesOfLead && lnUser?.lead.id) {
            leadsActions.getLeadSearch(lnUser?.lead.id, searchesOfLead[0].id);
        }
    }, [searchesOfLead]);
    useEffect(() => {
        if (updateConnectionLoading !== 'invite' && invitationRef.current) {
            invitationRef.current.close();
            setInvitationText('');
        }
    }, [updateConnectionLoading]);
    useEffect(() => {
        if (remind.type === 'remove') {
            lnUsersActions.updateRemind(lnUser, null, true);
        } else if (remind.type) {
            let { date } = remind;
            if (remind.type === '1day') {
                date = moment().add(1, 'days').startOf('day');
            } else if (remind.type === '3days') {
                date = moment().add(3, 'days').startOf('day');
            } else if (remind.type === '1week') {
                date = moment().add(7, 'days').startOf('day');
            }
            lnUsersActions.updateRemind(lnUser, date, true);
        }
    }, [remind]);
    useEffect(() => {
        if (lnUser?.conversations_ids?.length > 0 && !currentConversation) {
            linkedInActions.getMessages(
                moment(lnUser?.conversations_ids[0].lastactivity_at).valueOf() + 1,
                false,
                lnUser?.conversations_ids[0]
            );
            setCurrentConversation(lnUser?.conversations_ids[0]);
        }
    }, [lnUser?.id]);

    useEffect(() => {
        const convFound = lnUserConversations?.find((element) => element.linkedin_id === conversation);
        if (currentConversation?.id !== convFound?.id) {
            resetMessages();
            if (convFound) {
                linkedInActions.getMessages(moment(convFound.lastactivity_at).valueOf() + 1, false);
            }
        }
        setCurrentConversation(convFound);
    }, [lnUserConversations, conversation]);

    const reset = (doNotResetLnUser) => {
        if (!doNotResetLnUser) {
            resetLnUser();
        }
        setCurrentConversation(null);
        resetConversation();
        resetMessage();
        resetMessages();
        resetLnUserConversations();
        setEditNote(null);
        setEditNoteContent('');
        setNote('');
        setRemind({ type: null, date: new Date() });
        resetNotes();
    };
    const getConnectionsIndex = () => {
        const connects = getRecoil(connectionsState);
        return connects ? connects.items.findIndex((item) => item.id === lnUser.id) : -1;
    };
    const getLane = () => {
        const board = getRecoil(boardState);
        const lane = board?.lanes?.find((current) => parseInt(current.id, 10) === lnUser.step);
        return lane;
    };
    const getBoardIndex = () => {
        const lane = getLane();
        return lane ? lane?.cards?.findIndex((item) => parseInt(item.id, 10) === lnUser.id) : -1;
    };
    const hasNextMember = () => {
        if (location.pathname === '/') {
            const index = getConnectionsIndex();
            return index !== -1 && index < getRecoil(connectionsState)?.items.length - 1;
        }
        if (location.pathname === '/board') {
            const index = getBoardIndex();
            return index !== -1 && index < getLane()?.cards?.length - 1;
        }
        return false;
    };
    const hasPreviousMember = () => {
        if (location.pathname === '/') {
            const index = getConnectionsIndex();
            return index > 0;
        }
        if (location.pathname === '/board') {
            const index = getBoardIndex();
            return index !== -1 && index > 0;
        }
        return false;
    };
    const nextMember = () => {
        const index = location.pathname === '/' ? getConnectionsIndex() : getBoardIndex();
        if (index !== -1 && location.pathname === '/') {
            reset();
            setLnUser(getRecoil(connectionsState).items[index + 1]);
        }
        if (index !== -1 && location.pathname === '/board') {
            reset(true);
            actions.get(getLane().cards[index + 1].id, true);
        }
    };
    const previousMember = () => {
        const index = location.pathname === '/' ? getConnectionsIndex() : getBoardIndex();
        if (index !== -1 && location.pathname === '/') {
            reset();
            setLnUser(getRecoil(connectionsState).items[index - 1]);
        }
        if (index !== -1 && location.pathname === '/board') {
            reset(true);
            actions.get(getLane().cards[index - 1].id, true);
        }
    };
    function handlekeydownEvent(event) {
        const { keyCode } = event;
        if ((event.ctrlKey || event.metaKey) && keyCode === 74 && hasPreviousMember()) {
            // left arrow
            previousMember();
        } else if ((event.ctrlKey || event.metaKey) && keyCode === 75 && hasNextMember()) {
            // right arrow
            nextMember();
        }
    }
    useEffect(() => {
        document.addEventListener('keydown', handlekeydownEvent);
        return () => {
            document.removeEventListener('keydown', handlekeydownEvent);
        };
    }, [lnUser]);
    const hasMore = messages && messages.elements?.length === 20;
    const sponsorMessages = [];
    const displaySponsorMessages = messages?.elements?.length === 0 && lnUser.is_sponsor && currentConversation;
    if (displaySponsorMessages) {
        sponsorMessages.push(messageUtils.createMessage(currentConversation));
    }
    const messagesToDisplay = displaySponsorMessages ? sponsorMessages : messages?.elements;

    const onFileChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            // eslint-disable-next-line prefer-destructuring
            files = e.dataTransfer.files;
        } else if (e.target) {
            // eslint-disable-next-line prefer-destructuring
            files = e.target.files;
        }
        // eslint-disable-next-line prefer-destructuring
        const fileToRead = files[0];
        if (fileToRead) {
            const reader = new FileReader();
            reader.onload = () => {
                setFile({
                    name: fileToRead.name,
                    size: fileToRead.size,
                    type: fileToRead.type,
                    content: reader.result,
                });
            };

            reader.readAsDataURL(fileToRead);
        }
    };

    const onEmojiClick = (emojiData) => {
        if (messageInputRef.current?.inputRef?.current?.selectionStart) {
            setMessage(
                `${message.slice(0, messageInputRef.current?.inputRef?.current?.selectionStart)}${
                    emojiData.emoji
                }${message.slice(messageInputRef.current?.inputRef?.current?.selectionStart)}`
            );
        } else {
            setMessage(`${message}${emojiData.emoji}`);
        }
    };

    const onGifClick = (gifData) => {
        linkedInActions.sendMessage(messageTemplate, { externalMedia: gifData });
        setMessageTemplate(null);
    };
    const sendMessageDisabled = !messageUtils.canSendMessage(user, lnUser);
    const messageTemplates = templates?.filter((item) => item.type === 'message');
    const invitationTemplates = templates?.filter((item) => item.type === 'invitation');

    const searchTypeLabel = (searchParam) => {
        let origin = searchParam?.is_salesnav ? 'Sales Navigator' : 'Linkedin';
        if (searchParam?.profiles) {
            origin = 'CSV';
        }
        if (searchParam?.name === 'Leads moved from personal network') {
            return tr('Imported from Personal network');
        }
        if (searchParam?.is_salesnav || searchParam?.is_free_search || searchParam?.profiles) {
            return tr('Scraped from {{origin}}', {
                origin,
            });
        }
        return tr('List');
    };

    const lists = searches && searches?.items?.filter((item) => item.name !== 'Individual scrapes');

    if (!lnUser) {
        return null;
    }

    return (
        <Modal
            isFull
            noHeader
            closesWithEscape
            ref={modalRef}
            defaultIsOpen
            closeCb={() => reset()}
            labelValidate={tr('Close')}
            noFooter
            className={`${lnUser?.is_connection ? 'modal-relation' : 'modal-contact'} ${
                lnUser?.is_lead ? 'modal-lead' : ''
            } ${lnUser?.is_sponsor ? 'modal-sponsor' : ''}`}
        >
            <div className="content lnuser">
                <div className="lnuser__header">
                    <div className={`lnuser__header__left ${reduceLeft === 'leftNormal' ? 'large' : 'small'}`}>
                        {(location.pathname === '/' || location.pathname === '/board') && (
                            <Tooltip text={`${tr('Previous member')} (⌘/Ctrl + J)`} direction="e">
                                <Button
                                    disabled={!hasPreviousMember()}
                                    icon="arrow-left"
                                    type="bulk"
                                    onClick={() => previousMember()}
                                />
                            </Tooltip>
                        )}
                        <Tooltip
                            direction="e"
                            text={reduceLeft === 'leftNormal' ? tr('Hide member info') : tr('Display member info')}
                        >
                            <Button
                                icon={reduceLeft === 'leftNormal' ? 'reduce-left' : 'expand-left'}
                                type="bulk"
                                onClick={() =>
                                    reduceLeft === 'leftNormal'
                                        ? setReduceLeft('leftReduced')
                                        : setReduceLeft('leftNormal')
                                }
                            />
                        </Tooltip>
                    </div>
                    <div className={`lnuser__header__right ${reduceRight === 'rightNormal' ? 'large' : 'small'}`}>
                        <Tooltip
                            text={reduceRight === 'rightNormal' ? tr('Hide actions') : tr('Display actions')}
                            direction="w"
                        >
                            <Button
                                icon={reduceRight === 'rightNormal' ? 'reduce-right' : 'expand-right'}
                                type="bulk"
                                onClick={() =>
                                    reduceRight === 'rightNormal'
                                        ? setReduceRight('rightReduced')
                                        : setReduceRight('rightNormal')
                                }
                            />
                        </Tooltip>
                        <div>
                            {(location.pathname === '/' || location.pathname === '/board') && (
                                <Tooltip text={`${tr('Next member')} (⌘/Ctrl + K)`} direction="w">
                                    <Button
                                        disabled={!hasNextMember()}
                                        icon="arrow-right"
                                        type="bulk"
                                        onClick={() => nextMember()}
                                    />
                                </Tooltip>
                            )}
                            <Tooltip direction="w" text={tr('Press "ESC" key to close.')}>
                                <Button type="link" icon="close" onClick={() => modalRef?.current.close()} />
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className="lnuser__content">
                    <div
                        className={`lnuser__sidebar left ${
                            reduceLeft !== '' && reduceLeft === 'leftNormal' ? 'show-left-sidebar' : 'hide-left-sidebar'
                        }`}
                    >
                        <div className="lnuser__sidebar__header">
                            <div className="connection-status">
                                <span
                                    className={`status-badge ${
                                        lnUser?.is_connection ? 'status-relation' : 'status-contact'
                                    } ${lnUser?.is_lead ? 'status-lead' : ''} ${
                                        lnUser?.is_sponsor ? 'status-sponsor' : ''
                                    }`}
                                />
                                <span className="ellipsis mr--15">{messageUtils.userRelation(lnUser, true)}</span>
                            </div>
                            <div className="flex">
                                <TooltipPopover
                                    className="follow-btn"
                                    text={tr('Available quota: {{quota}}/{{total}} - Last update: {{update}}', {
                                        quota: user?.quotas?.daily_import_standard,
                                        total: user?.quotas?.daily_import_standard_conf,
                                        update: moment(lnUser?.updated_at).format('ll'),
                                    })}
                                    direction="s"
                                >
                                    {user?.tours && user?.tours.indexOf('refreshProfil2') !== -1 && (
                                        <Button
                                            size="small"
                                            icon="sync"
                                            type="white"
                                            isBordered
                                            disabled={user?.quotas?.daily_import_standard <= 0}
                                            isLoading={syncLnUser}
                                            label={tr('Update')}
                                            onClick={() => {
                                                linkedInActions.updateLnUser(undefined, true);
                                            }}
                                        />
                                    )}
                                    {(!user?.tours || user?.tours.indexOf('refreshProfil2') === -1) && (
                                        <ConfirmButton
                                            size="small"
                                            icon="sync"
                                            type="white"
                                            isBordered
                                            disabled={user?.quotas?.daily_import_standard <= 0}
                                            isLoading={syncLnUser}
                                            label={tr('Update')}
                                            confirmLabel={tr(
                                                'Updating the user profile is similar to exporting members from LinkedIn again. Are you sure you want to use your daily quota?'
                                            )}
                                            onConfirm={() => {
                                                userActions.updateTour(
                                                    user.tours && user.tours.indexOf('refreshProfil1') !== -1
                                                        ? 'refreshProfil2'
                                                        : 'refreshProfil1'
                                                );
                                                linkedInActions.updateLnUser(undefined, true);
                                            }}
                                        />
                                    )}
                                </TooltipPopover>
                            </div>
                        </div>
                        {(lnUserConversations?.length !== 0 || messagesToDisplay?.length !== 0) && (
                            <>
                                {lnUser?.invitation_id && lnUser?.invitation_type === 'PENDING' && (
                                    <div className="lnuser__sidebar__section actions-button two-buttons">
                                        <Button
                                            icon="user-check"
                                            label={tr('Accept')}
                                            isLoading={updateConnectionLoading === 'accept'}
                                            disabled={!!updateConnectionLoading}
                                            onClick={() => linkedInActions.acceptConnection(true)}
                                        />
                                        <ConfirmButton
                                            type="secondary-dark"
                                            icon="user-remove"
                                            label={tr('Ignore')}
                                            confirmLabel={tr(
                                                'Are you sure you want to ignore the connection requests?'
                                            )}
                                            isLoading={updateConnectionLoading === 'ignore'}
                                            disabled={!!updateConnectionLoading}
                                            onConfirm={() => linkedInActions.acceptConnection(false)}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                        {!sendMessageDisabled && lnUser?.invitation_id && lnUser?.invitation_type === 'SENT' && (
                            <div className="lnuser__sidebar__section actions-button">
                                <Button
                                    className="btn--withdraw"
                                    icon="user-remove"
                                    label={tr('Withdraw invitation')}
                                    isLoading={updateConnectionLoading === 'ignore'}
                                    disabled={!!updateConnectionLoading}
                                    onClick={() => linkedInActions.withdrawInvitation()}
                                />
                            </div>
                        )}

                        {!lnUser?.is_groupchat &&
                            (messagesToDisplay || messageUtils.canSendMessage(user, lnUser)) &&
                            !lnUser?.is_connection &&
                            !lnUser?.invitation_id &&
                            lnUser?.lead.linkedin_public_id !== 'UNKNOWN' && (
                                <div className="lnuser__sidebar__section actions-button">
                                    <Button
                                        icon="user-add"
                                        className={lnUser?.is_connection ? 'btn--relation' : 'btn--contact'}
                                        label={tr('Connect')}
                                        onClick={() => invitationRef.current.open()}
                                    />
                                    <Modal
                                        ref={invitationRef}
                                        validateCb={() => linkedInActions.invite(invitationText)}
                                        title={tr('Send a connection request')}
                                        overflow
                                    >
                                        <Flash fill color="orange" className="mb--20">
                                            {tr(
                                                'Be careful, with LinkedIn, you are limited to 100 connection requests per week and 200 or 300 characters per message.'
                                            )}
                                        </Flash>
                                        {user?.quotas?.daily_invite > 20 && (
                                            <Flash fill color="orange" className="mb--20">
                                                {tr(
                                                    `We've noticed that you've already sent ${user?.quotas?.daily_invite} today.`
                                                )}
                                            </Flash>
                                        )}
                                        {invitationTemplates?.length > 0 && (
                                            <Dropdown
                                                btnFaceIcon="message-add"
                                                btnFace="secondary"
                                                label={tr('Use a template message')}
                                                onClick={(e) => e.stopPropagation()}
                                                onDoubleClick={(e) => e.stopPropagation()}
                                            >
                                                <Dropdown.Menu direction="s">
                                                    {invitationTemplates?.map((element) => (
                                                        <Dropdown.ButtonItem
                                                            isSubscriptionPopover
                                                            key={element?.id}
                                                            btnLabel={element.title}
                                                            onClick={() => {
                                                                let mess;
                                                                if (
                                                                    element.alternative &&
                                                                    !utils.canReplaceVariables(element.content, lnUser)
                                                                ) {
                                                                    mess = utils.replaceVariables(
                                                                        element.alternative,
                                                                        lnUser
                                                                    );
                                                                } else {
                                                                    mess = utils.replaceVariables(
                                                                        element.content,
                                                                        lnUser
                                                                    );
                                                                }
                                                                setInvitationText(mess);
                                                            }}
                                                        />
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        )}
                                        <Input
                                            type="textarea"
                                            maxlength={user?.has_sales_nav || user?.is_premium ? '300' : '200'}
                                            placeholder={tr('Type in a short text.')}
                                            value={invitationText}
                                            onChange={(e) => setInvitationText(e.target.value)}
                                        />
                                    </Modal>
                                </div>
                            )}
                        <div className="lnuser__sidebar__section-scroll">
                            <div className="lnuser__sidebar__section">
                                <Avatar
                                    className="lnuser__user__avatar"
                                    src={lnUser?.lead.picture || avatar}
                                    alt={`${lnUser?.lead.firstname} ${lnUser?.lead.lastname}`}
                                />
                            </div>

                            {lnUser?.connected_at && (
                                <div className="lnuser__sidebar__section__extra-info">
                                    <div>
                                        {tr('Connection since ')}
                                        {moment(lnUser?.connected_at).format('LL')}
                                    </div>
                                </div>
                            )}

                            {(lnUser?.lead.is_premium ||
                                lnUser?.lead.is_open_profile ||
                                lnUser?.lead.is_opentowork) && (
                                <div className="lnuser__sidebar__section__extra-info">
                                    {lnUser?.lead.is_premium && (
                                        <div className="privilege privilege--premium">{tr('LinkedIn Premium')}</div>
                                    )}
                                    {lnUser?.lead.is_open_profile && (
                                        <div className="privilege privilege--open-profile">{tr('Open profile')}</div>
                                    )}
                                    {lnUser?.lead.is_opentowork && (
                                        <div className="privilege privilege--open-to-work">{tr('Open to work')}</div>
                                    )}
                                </div>
                            )}
                            <div className="lnuser__sidebar__section lnuser__sidebar__section__info">
                                <div className="lnuser__sidebar__section__user-info">
                                    <h1 className="lnuser__user__name">
                                        {lnUser?.lead.firstname} {lnUser?.lead.lastname}
                                    </h1>
                                    {lnUser?.lead.headline && (
                                        <div className="lnuser__user__headline">{lnUser?.lead.headline}</div>
                                    )}
                                    {(lnUser?.lead.location || lnUser?.lead.country) && (
                                        <div className="lnuser__user__location">
                                            {lnUser?.lead.location || lnUser?.lead.country}
                                        </div>
                                    )}
                                    {lnUser?.lead.email && (
                                        <div
                                            className={`lnuser__user__email ${
                                                lnUser?.is_enrich ? 'enriched-email' : ''
                                            }`}
                                        >
                                            {lnUser?.is_enrich && (
                                                <Tooltip text={tr('Email found')} direction="e">
                                                    <span className="enriched-profile">
                                                        <Icon size={9} icon="check" />
                                                    </span>
                                                </Tooltip>
                                            )}
                                            <span>{lnUser?.lead.email}</span>
                                        </div>
                                    )}
                                    {lnUser?.lead.email_enrich && (
                                        <div
                                            className={`lnuser__user__email ${
                                                lnUser?.is_enrich ? 'enriched-email' : ''
                                            }`}
                                        >
                                            {lnUser?.is_enrich && (
                                                <Tooltip text={tr('Email Found')} direction="e">
                                                    <span className="enriched-profile">
                                                        <Icon size={9} icon="check" />
                                                    </span>
                                                </Tooltip>
                                            )}
                                            <span>{lnUser?.lead.email_enrich}</span>
                                        </div>
                                    )}
                                    {!lnUser?.email &&
                                        !lnUser?.lead.email &&
                                        !lnUser?.lead.email_enrich &&
                                        !lnUser?.lead.email_2 &&
                                        lnUser?.is_enrich &&
                                        lnUser?.lead.dropcontact_request_completed && (
                                            <div className="lnuser__user__email mbi--small">
                                                <Icon size={16} icon="close-circle" />
                                                <span>{tr('Email not found')}</span>
                                            </div>
                                        )}
                                    {!lnUser?.is_groupchat &&
                                        (!lnUser?.lead.email || !lnUser?.lead.phone) &&
                                        !lnUser?.lead.dropcontact_request_completed &&
                                        !lnUser?.is_enrich && (
                                            <>
                                                {lnUser?.lead.company && (
                                                    <Tooltip
                                                        wrap
                                                        text={
                                                            user?.team?.email_credits === 0
                                                                ? tr("You've reached your quota of Email Credit.")
                                                                : tr(
                                                                      'An Email Credit will be deducted if the email address is found.'
                                                                  )
                                                        }
                                                        direction="s"
                                                    >
                                                        <Button
                                                            label={tr('Find email address')}
                                                            icon="arobase"
                                                            disabled={
                                                                user?.team?.email_credits === 0 || !lnUser?.lead.company
                                                            }
                                                            isLoading={enrichLnUser}
                                                            onClick={() => actions.enrich()}
                                                            type="yellow"
                                                        />
                                                    </Tooltip>
                                                )}
                                                {!lnUser?.lead.company && (
                                                    <Tooltip
                                                        wrap
                                                        text={tr(
                                                            'User does not have campany filled in. Please, refresh the profile.'
                                                        )}
                                                        direction="s"
                                                    >
                                                        <Button
                                                            label={tr('Find email address')}
                                                            icon="arobase"
                                                            disabled
                                                            isLoading={enrichLnUser}
                                                            onClick={() => actions.enrich()}
                                                            type="yellow"
                                                        />
                                                    </Tooltip>
                                                )}
                                            </>
                                        )}
                                    {!lnUser?.lead.dropcontact_request_completed && lnUser?.is_enrich && (
                                        <>
                                            <Flash className="enrichement">
                                                <span>{tr('Enrichment in progress...')}</span>
                                                <Button
                                                    size="small"
                                                    type="link-flex"
                                                    label={tr('Refresh')}
                                                    isLoading={loadingLnUser}
                                                    onClick={() => actions.get()}
                                                />
                                            </Flash>
                                        </>
                                    )}
                                    {lnUser?.lead.phone && (
                                        <div className="lnuser__user__phone">{lnUser?.lead.phone}</div>
                                    )}
                                    <LnUserCustomInfo />
                                    {lnUser?.linkedin_public_id !== 'UNKNOWN' && (
                                        <Button
                                            isToggle
                                            isLoading={!!lnUserFollowLoading}
                                            type="secondary-blue"
                                            active={lnUser?.is_followed}
                                            label={lnUser?.is_followed ? tr('Unfollow') : tr('Follow')}
                                            onClick={() =>
                                                lnUser?.is_followed
                                                    ? linkedInActions.follow(false)
                                                    : linkedInActions.follow(true)
                                            }
                                        />
                                    )}
                                    <>
                                        {lnUser?.lead.skills && lnUser?.lead.skills.length > 0 && (
                                            <div className="lnuser__user__skills">
                                                <h5 className="mt--10 mb--20">{tr('Skills')}</h5>

                                                <div className="lnuser__user__skills-container">
                                                    {lnUser?.lead.skills.map((skill) => (
                                                        <div className="skill" key={skill}>
                                                            <span className="ellipsis">{skill}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        <div className="lnuser__sidebar__section__stats">
                                            {lnUser?.lead.connections && (
                                                <div
                                                    className={`box ${
                                                        lnUser?.is_connection ? 'box--relation' : 'box--contact'
                                                    }`}
                                                >
                                                    <span className="box__value" title={lnUser?.lead.connections}>
                                                        {lnUser?.lead.connections}
                                                    </span>
                                                    <h5 className="box__label">{tr('Connections')}</h5>
                                                </div>
                                            )}

                                            {lnUser?.lead.follower && (
                                                <div
                                                    className={`box ${
                                                        lnUser?.is_connection ? 'box--relation' : 'box--contact'
                                                    }`}
                                                >
                                                    <span className="box__value" title={lnUser?.lead.follower}>
                                                        {lnUser?.lead.follower}
                                                    </span>
                                                    <h5 className="box__label">{tr('Followers')}</h5>
                                                </div>
                                            )}
                                        </div>
                                        {!lnUser?.is_groupchat &&
                                            lnUser?.lead.linkedin_public_id &&
                                            lnUser?.lead.linkedin_public_id !== 'UNKNOWN' && (
                                                <div className="lnuser__user__visit">
                                                    <a
                                                        className="btn btn--secondary-blue mb--20"
                                                        href={`https://www.linkedin.com/in/${lnUser?.lead.linkedin_public_id}`}
                                                        type="secondary-blue"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {tr('See profile on LinkedIn')}
                                                    </a>
                                                </div>
                                            )}

                                        <div className="lnuser__sidebar__section__separator" />
                                        <div className="lnuser__sidebar__section__group">
                                            {lnUser?.lead.summary && (
                                                <>
                                                    <div className="lnuser__sidebar__section__current__info">
                                                        <h5>{tr('Profile Summary')}</h5>
                                                        <div className="lnuser__user__summary">
                                                            {lnUser?.lead?.summary}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {lnUser?.lead.job && (
                                                <div className="lnuser__sidebar__section__current__info">
                                                    <h5>{tr('Current Position')}</h5>
                                                    <div className="lnuser__user__job">
                                                        <strong>{lnUser?.lead.job}</strong>
                                                    </div>
                                                    {lnUser?.lead.industry && (
                                                        <div className="lnuser__user__industry">
                                                            {lnUser?.lead.industry}
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                            {lnUser?.lead.company && (
                                                <>
                                                    <div className="lnuser__sidebar__section__current">
                                                        <h5>{tr('Current Company')}</h5>
                                                        <div className="lnuser__user__company">
                                                            {lnUser?.lead.company_website ? (
                                                                <a
                                                                    href={lnUser?.lead.company_website}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {lnUser?.lead.company}
                                                                </a>
                                                            ) : (
                                                                lnUser?.lead.company
                                                            )}
                                                        </div>
                                                        {lnUser?.lead.company_industry && (
                                                            <div className="lnuser__user__industry">
                                                                {lnUser?.lead.company_industry}
                                                            </div>
                                                        )}
                                                        <div className="lnuser__company__card__meta">
                                                            {lnUser?.lead.company_year_founded && (
                                                                <span>
                                                                    &bull; {tr('Founded in')}{' '}
                                                                    {lnUser?.lead.company_year_founded}
                                                                </span>
                                                            )}
                                                            {lnUser?.lead.company_employee_count && (
                                                                <span>
                                                                    &bull; {lnUser?.lead.company_employee_count}{' '}
                                                                    {tr('employees')}
                                                                </span>
                                                            )}
                                                            {lnUser?.lead.company_location && (
                                                                <span>&bull; {lnUser?.lead.company_location}</span>
                                                            )}
                                                        </div>
                                                        {lnUser?.lead.company_linkedin && (
                                                            <div className="lnuser__user__company__website">
                                                                <a
                                                                    href={lnUser?.lead.company_linkedin}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {tr('LinkedIn company page')}
                                                                </a>
                                                            </div>
                                                        )}

                                                        <div className="lnuser__company__card__info__section">
                                                            {lnUser?.lead.company_type && (
                                                                <div>{lnUser?.lead.company_type}</div>
                                                            )}
                                                        </div>
                                                        <div className="lnuser__company__card__info__section">
                                                            {lnUser?.lead.company_description && (
                                                                <div>{lnUser?.lead.company_description}</div>
                                                            )}
                                                            {lnUser?.lead.company_specialties && (
                                                                <div>
                                                                    {tr('Specialities:')}{' '}
                                                                    {lnUser?.lead.company_specialties.join(', ')}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div />
                                                </>
                                            )}
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`lnuser__body ${reduceLeft === 'leftReduced' ? 'lnuser__body--left-radius' : ''} ${
                            reduceRight === 'rightReduced' ? 'lnuser__body--right-radius' : ''
                        }`}
                    >
                        {(lnUserConversations?.length > 1 || lnUser?.templates?.length > 0) && (
                            <div className="lnuser__body__header">
                                <div className="lnuser__body__header__content">
                                    {lnUserConversations?.length > 1 && (
                                        <div className="multiple-conversations">
                                            <Dropdown
                                                btnFace="secondary"
                                                btnFaceIcon="message-multi"
                                                label={tr('Conversation from {{date}}', {
                                                    date: moment(currentConversation?.lastactivity_at).format('LLL'),
                                                })}
                                                onClick={(e) => e.stopPropagation()}
                                                onDoubleClick={(e) => e.stopPropagation()}
                                                testId="last-conversations"
                                            >
                                                <Dropdown.Menu>
                                                    {lnUserConversations?.map((element) => (
                                                        <Dropdown.ButtonItem
                                                            key={element.linkedin_id}
                                                            active={element.id === currentConversation?.id}
                                                            btnLabel={
                                                                tr('Conversation from {{date}}', {
                                                                    date: moment(element?.lastactivity_at).format(
                                                                        'LLL'
                                                                    ),
                                                                }) + (element?.is_read ? '' : ` - ${tr('Not read')}`)
                                                            }
                                                            onClick={() => {
                                                                setConversation(element.linkedin_id);
                                                            }}
                                                        />
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    )}

                                    {lnUser?.templates?.length > 0 && (
                                        <div className="template-messages">
                                            <Dropdown
                                                btnFace="secondary"
                                                btnFaceIcon="document"
                                                label={tr('{{count}} template messages sent', {
                                                    count: lnUser?.templates?.length,
                                                })}
                                                onClick={(e) => e.stopPropagation()}
                                                onDoubleClick={(e) => e.stopPropagation()}
                                                testId="template-message-sent"
                                            >
                                                <Dropdown.Menu>
                                                    {lnUser?.templates?.map((template) => (
                                                        <Dropdown.Item key={template}>
                                                            <div className="template-messages-sent">
                                                                <span className="ellipsis">{template}</span>
                                                            </div>
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <div
                            id="chat"
                            className={`lnuser__body__chat ${!messages ? 'chat-empty' : ''} ${
                                sendMessageDisabled && !messages ? 'chat-disabled' : ''
                            }`}
                        >
                            {messages && (
                                <InfiniteScroll
                                    inverse
                                    dataLength={messagesToDisplay?.length || 0}
                                    next={() =>
                                        linkedInActions.getMessages(
                                            messagesToDisplay[messagesToDisplay?.length - 1].createdAt - 1,
                                            true
                                        )
                                    }
                                    className="lnuser__body__chat__content"
                                    hasMore={hasMore}
                                    loader={<Icon isze={60} isLoad icon="load" />}
                                    scrollableTarget="chat"
                                    initialScrollY={1000}
                                >
                                    {messagesToDisplay.map((mess) => (
                                        <div
                                            className={`chat ${
                                                lnUser.lead.linkedin_public_id === messageUtils.authorPublicId(mess)
                                                    ? ''
                                                    : 'me'
                                            } ${messageUtils.isSponsored(mess) ? 'sponsored' : ''}`}
                                            key={mess.dashEntityUrn}
                                        >
                                            <img
                                                className="chat__avatar"
                                                src={messageUtils.avatar(mess) || avatar}
                                                alt={messageUtils.author(mess, lnUser)}
                                            />
                                            {!messageUtils.isSponsored(mess) && (
                                                <div className="chat__reaction-btn">
                                                    <EmojiPicker
                                                        placement="top-center"
                                                        onEmojiClick={(emojiData) =>
                                                            linkedInActions.reactOnMessage(
                                                                messageUtils.messageUrn(mess, user),
                                                                emojiData.emoji,
                                                                true
                                                            )
                                                        }
                                                        btnIconSize={22}
                                                        btnIcon="emoji-add"
                                                        btnSize="small"
                                                        btnType="transparent"
                                                        isRound
                                                    />
                                                </div>
                                            )}
                                            <div className="chat__body">
                                                <div className="chat__header">
                                                    <div className="chat__name">
                                                        {messageUtils.author(mess, lnUser)}
                                                    </div>
                                                    <div className="chat__timestamp">- {messageUtils.date(mess)}</div>
                                                    {messageUtils.isSponsored(mess) && (
                                                        <div className="chat__sponsored">{tr('Sponsored message')}</div>
                                                    )}
                                                </div>
                                                <div className="chat__message">
                                                    <div className="chat__message__display">
                                                        {messageUtils.text(mess)}
                                                    </div>
                                                    <div className="chat__message__shared">
                                                        {messageUtils.sharedPost(mess)}
                                                    </div>
                                                    <div className="chat__message__reactions">
                                                        {messageUtils.reactions(mess, lnUser, (emoji) => {
                                                            linkedInActions.reactOnMessage(
                                                                messageUtils.messageUrn(mess, user),
                                                                emoji,
                                                                false
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="chat__attachment">{messageUtils.attachments(mess)}</div>
                                            </div>
                                            <div className="chat__message__read">
                                                {messageUtils.read(mess, currentConversation, lnUser, messages)}
                                            </div>
                                        </div>
                                    ))}
                                </InfiniteScroll>
                            )}
                            {(lnUserConversations?.length === 0 || messagesToDisplay?.length === 0) &&
                                (!sendMessageDisabled ? (
                                    <div className="empty">
                                        <Icon isEmpty icon="empty-message" size={360} />
                                        <h1>{tr('Start a conversation')}</h1>
                                    </div>
                                ) : (
                                    <>
                                        {lnUser?.invitation_id && lnUser?.invitation_type === 'PENDING' && (
                                            <div className="lnuser__body__chat__content__invitation">
                                                <div className="empty">
                                                    <Icon isEmpty icon="empty-invitation" size={320} />
                                                </div>
                                                <h1>
                                                    {tr('{{name}} sends you an invitation', {
                                                        name: `${lnUser?.lead.firstname} ${lnUser?.lead.lastname}`,
                                                    })}
                                                </h1>
                                                {lnUser?.invitation_message && (
                                                    <p className="mb--20">{utils.nl2br(lnUser?.invitation_message)}</p>
                                                )}
                                                <div className="flex mbi">
                                                    <Button
                                                        icon="user-check"
                                                        label={tr('Accept')}
                                                        isLoading={updateConnectionLoading === 'accept'}
                                                        disabled={!!updateConnectionLoading}
                                                        onClick={() => linkedInActions.acceptConnection(true)}
                                                    />
                                                    <ConfirmButton
                                                        type="secondary-white"
                                                        icon="user-remove"
                                                        label={tr('Ignore')}
                                                        confirmLabel={tr(
                                                            'Are you sure you want to ignore the invitation?'
                                                        )}
                                                        isLoading={updateConnectionLoading === 'ignore'}
                                                        disabled={!!updateConnectionLoading}
                                                        onConfirm={() => linkedInActions.acceptConnection(false)}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {lnUser?.invitation_id && lnUser?.invitation_type === 'SENT' && (
                                            <div className="lnuser__body__chat__content__invitation">
                                                <div className="empty">
                                                    <Icon isEmpty icon="empty-sent-invitation" size={360} />
                                                </div>
                                                <h1>
                                                    {tr('You have sent a connection request to {{name}}', {
                                                        name: `${lnUser?.lead.firstname} ${lnUser?.lead.lastname}`,
                                                    })}
                                                </h1>
                                                <Button
                                                    className="btn--withdraw"
                                                    icon="user-remove"
                                                    label={tr('Withdraw connection request')}
                                                    isLoading={updateConnectionLoading === 'ignore'}
                                                    disabled={!!updateConnectionLoading}
                                                    onClick={() => linkedInActions.withdrawInvitation()}
                                                />
                                            </div>
                                        )}

                                        {!lnUser?.is_groupchat &&
                                            !messagesToDisplay &&
                                            !lnUser?.invitation_id &&
                                            lnUser?.linkedin_public_id !== 'UNKNOWN' && (
                                                <div className="lnuser__body__chat__content__invite">
                                                    <h1>
                                                        {tr('Invite {{name}} to join your network', {
                                                            name: `${lnUser?.lead.firstname} ${lnUser?.lead.lastname}`,
                                                        })}
                                                    </h1>
                                                    <div className="lnuser__body__chat__content__template">
                                                        {invitationTemplates?.length > 0 && (
                                                            <Dropdown
                                                                btnFaceIcon="message-add"
                                                                btnFace="secondary"
                                                                label={tr('Use a template message')}
                                                                onClick={(e) => e.stopPropagation()}
                                                                onDoubleClick={(e) => e.stopPropagation()}
                                                            >
                                                                <Dropdown.Menu direction="s">
                                                                    {invitationTemplates?.map((element) => (
                                                                        <Dropdown.ButtonItem
                                                                            isSubscriptionPopover
                                                                            key={element?.id}
                                                                            btnLabel={element.title}
                                                                            onClick={() => {
                                                                                setInvitationText(
                                                                                    utils.replaceVariables(
                                                                                        element.content,
                                                                                        lnUser
                                                                                    )
                                                                                );
                                                                            }}
                                                                        />
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        )}
                                                        <span className="optional">{tr('* Optional')}</span>
                                                    </div>
                                                    <Input
                                                        placeholder={tr('Type a message *')}
                                                        type="textarea"
                                                        maxlength={
                                                            user?.has_sales_nav || user?.is_premium ? '300' : '200'
                                                        }
                                                        value={invitationText}
                                                        onChange={(e) => setInvitationText(e.target.value)}
                                                    />
                                                    <Button
                                                        icon="user-add"
                                                        label={tr('Connect to {{name}}', {
                                                            name: `${lnUser?.lead.firstname} ${lnUser?.lead.lastname}`,
                                                        })}
                                                        onClick={() => {
                                                            linkedInActions.invite(invitationText);
                                                            setInvitationText('');
                                                        }}
                                                    />
                                                    <Flash fill color="orange" className="mt--20">
                                                        {tr(
                                                            'Be careful, with LinkedIn, you are limited to 100 connection requests per week and 200 or 300 characters per message.'
                                                        )}
                                                    </Flash>
                                                </div>
                                            )}
                                    </>
                                ))}
                        </div>
                        <div className="lnuser__body__chat__footer">
                            <div className="publication">
                                <div className="publication__wrapper">
                                    {file && (
                                        <div className="publication__file publication__attachment">
                                            <span className="ellipsis">{file.name}</span>
                                            <Button
                                                icon="close"
                                                size="mini"
                                                type="link"
                                                onClick={() => setFile(null)}
                                            />
                                        </div>
                                    )}
                                    {attachments &&
                                        attachments.map((attachment) => (
                                            <div
                                                className="publication__attachment"
                                                key={attachment.file?.name || attachment.externalMedia?.title}
                                            >
                                                <span className="ellipsis">
                                                    {attachment.file?.name || attachment.externalMedia?.title}
                                                </span>
                                                <Button
                                                    icon="close"
                                                    size="mini"
                                                    type="link"
                                                    onClick={() => {
                                                        const newAttachments = [...attachments];
                                                        newAttachments.splice(attachment.indexOf(attachments), 1);
                                                        setAttachments(newAttachments);
                                                    }}
                                                />
                                            </div>
                                        ))}

                                    {!sendMessageDisabled && (
                                        <div className="publication__header">
                                            {lnUser?.lead.is_open_profile && !lnUser?.is_connection && (
                                                <div className="publication__header__actions-left">
                                                    {lnUser?.lead.is_open_profile && (
                                                        <Tooltip
                                                            text={tr(
                                                                'This user is an Open Profile. You can send him a message even if he is not part of your connections.'
                                                            )}
                                                        >
                                                            <span className="inmail">{tr('Free InMail')}</span>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            )}
                                            <div className="publication__header__actions-right">
                                                {messageTemplates?.length > 0 ? (
                                                    <Dropdown
                                                        label={tr('Use a template message')}
                                                        btnFaceIcon="document-add"
                                                        onClick={(e) => e.stopPropagation()}
                                                        onDoubleClick={(e) => e.stopPropagation()}
                                                    >
                                                        <Dropdown.Menu direction="ne">
                                                            {messageTemplates?.map((element) => (
                                                                <Dropdown.ButtonItem
                                                                    isSubscriptionPopover
                                                                    key={element?.id}
                                                                    btnLabel={element.title}
                                                                    onClick={() => {
                                                                        let mess;
                                                                        let attach = [];
                                                                        if (
                                                                            element.alternative &&
                                                                            !utils.canReplaceVariables(
                                                                                element.content,
                                                                                lnUser
                                                                            )
                                                                        ) {
                                                                            mess = utils.replaceVariables(
                                                                                element.alternative,
                                                                                lnUser
                                                                            );
                                                                            attach = element.alternative_attachment;
                                                                        } else {
                                                                            mess = utils.replaceVariables(
                                                                                element.content,
                                                                                lnUser
                                                                            );
                                                                            attach = element.attachment;
                                                                        }
                                                                        setMessage(mess);
                                                                        setMessageTemplate(element.title);
                                                                        setAttachments(JSON.parse(attach));
                                                                    }}
                                                                />
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                ) : (
                                                    <Link
                                                        className="btn--link"
                                                        to="/templates"
                                                        onClick={() => modalRef?.current.close()}
                                                    >
                                                        {tr('+ Create a template message')}
                                                    </Link>
                                                )}
                                                <Tooltip text={expand === 'reduced' ? tr('Reduce') : tr('Expand')}>
                                                    <Button
                                                        type="link"
                                                        icon={expand === 'reduced' ? 'reduce' : 'expand'}
                                                        iconSize={18}
                                                        onClick={() =>
                                                            expand === 'reduced'
                                                                ? setExpand('expanded')
                                                                : setExpand('reduced')
                                                        }
                                                    />
                                                </Tooltip>
                                            </div>
                                        </div>
                                    )}
                                    <Input
                                        className={expand === 'reduced' && expand !== '' ? 'expanded' : ''}
                                        type="textarea"
                                        disabled={sendMessageDisabled}
                                        placeholder={
                                            (user?.is_premium || lnUser?.lead.is_open_profile) && !lnUser?.is_connection
                                                ? tr('Type your InMail')
                                                : tr('Type your message')
                                        }
                                        ref={messageInputRef}
                                        value={message}
                                        onPressCmdEnter={() => {
                                            linkedInActions.sendMessage(messageTemplate);
                                            setMessageTemplate(null);
                                        }}
                                        onChange={(e) => setMessage(e.target.value)}
                                    />
                                    {!sendMessageDisabled && (
                                        <div className="publication__footer">
                                            <div className="emoji-wrapper">
                                                <EmojiPicker placement="top-center" onEmojiClick={onEmojiClick} />
                                            </div>
                                            <Button
                                                className="attach"
                                                disabled={lnUserSendingMessage}
                                                type="link"
                                                icon="paperclip"
                                                onClick={() => {
                                                    fileRef.current.click();
                                                }}
                                            />
                                            <div className="gif-picker-wrapper">
                                                <GifPicker
                                                    btnType="primary"
                                                    placement="top-center"
                                                    onGifClick={onGifClick}
                                                />
                                            </div>
                                            <Button
                                                className="send"
                                                icon="send"
                                                type={
                                                    lnUser?.lead.is_open_profile && !lnUser?.is_connection
                                                        ? 'openprofile'
                                                        : 'primary'
                                                }
                                                isLoading={lnUserSendingMessage}
                                                disabled={
                                                    !message && !file && (!attachments || attachments.length === 0)
                                                }
                                                onClick={() => {
                                                    linkedInActions.sendMessage(messageTemplate);
                                                    setMessageTemplate(null);
                                                }}
                                            />
                                            <input
                                                style={{ display: 'none' }}
                                                ref={fileRef}
                                                type="file"
                                                onChange={onFileChange}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`lnuser__sidebar right ${
                            reduceRight === 'rightNormal' ? 'show-right-sidebar' : 'hide-right-sidebar'
                        }`}
                    >
                        <div className="lnuser__sidebar__section-scroll">
                            {searchesOfLead && searchesOfLead.length > 0 && (
                                <div className="lnuser__sidebar__section">
                                    <div className="lnuser__origin">
                                        <div className="lnuser__origin__header">
                                            <h5>{tr('Origin')}</h5>
                                        </div>

                                        <div className="lnuser__origin__body">
                                            <h4 className="mb--10">{searchTypeLabel(searchesOfLead[0])}</h4>
                                            <Link
                                                className="btn btn--has-icon btn--secondary btn--bordered mb--10"
                                                to={`/searches/${searchesOfLead[0].id}`}
                                                onClick={() => modalRef.current?.close()}
                                            >
                                                <Icon icon={utils.searchTypeIcon(searchesOfLead[0])} />
                                                <span className="btn__label">{tr(searchesOfLead[0].name)}</span>
                                            </Link>
                                            <Button
                                                type="secondary-dark"
                                                icon="folder-arrow"
                                                label={tr('Move to a list')}
                                                onClick={() => modalMoveRef.current.open()}
                                            />
                                            <Modal
                                                ref={modalMoveRef}
                                                validateCb={
                                                    !listName
                                                        ? () => {
                                                              leadsActions.changeList(
                                                                  searchesOfLead[0],
                                                                  lnUser?.lead,
                                                                  selectedSearch
                                                              );
                                                              modalMoveRef?.current.close();
                                                          }
                                                        : () => {
                                                              leadsActions.createList(
                                                                  searchesOfLead[0],
                                                                  lnUser?.lead,
                                                                  listName
                                                              );
                                                              modalMoveRef?.current.close();
                                                          }
                                                }
                                                title={tr('Move to a list')}
                                                disabled={!selectedSearch && !listName}
                                                labelValidate={lists?.length === 0 ? tr('Create and move') : tr('Move')}
                                                overflow
                                            >
                                                {lists?.length === 0 && (
                                                    <p className="red mt--30 mb--15">
                                                        {tr('You do not have any list yet.')}
                                                    </p>
                                                )}
                                                <Select
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                    placeholder={tr('Select a list')}
                                                    onChange={(selected) => {
                                                        setSelectedSearch(selected.value);
                                                    }}
                                                    isDisabled={lists?.length === 0}
                                                    options={lists?.map((current) => ({
                                                        value: current.id,
                                                        name: current.name,
                                                        label: current.name,
                                                    }))}
                                                />
                                                <div className="my--20 center">OR</div>

                                                <Input
                                                    label={tr('Create a new list')}
                                                    placeholder={tr('List name')}
                                                    value={listName}
                                                    onChange={(e) => setListName(e.target.value)}
                                                />
                                            </Modal>
                                        </div>
                                        {searchesOfLead[0]?.is_salesnav && !leadSearch?.is_match && (
                                            <div className="lnuser__match">
                                                <div className="lnuser__match__label lnuser__match__label--nok">
                                                    NOT MATCH
                                                </div>
                                                <div className="lnuser__match__reason">
                                                    {leadSearch?.no_match_reasons.join(', ')}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            <div className="lnuser__sidebar__section">
                                <div className="lnuser__note">
                                    <h5>{tr('Notes')}</h5>
                                    {notes?.length === 0 && !addNote && (
                                        <Button
                                            icon="sticker"
                                            type="secondary-dark"
                                            label={tr('Add note')}
                                            onClick={() => setAddNote(true)}
                                        />
                                    )}
                                    {notes?.length === 0 && addNote && (
                                        <div className="lnuser__note__add">
                                            <Input
                                                type="textarea"
                                                value={note}
                                                onChange={(e) => setNote(e.target.value)}
                                            />
                                            <div className="flex-right mbi--small">
                                                <Button
                                                    size="small"
                                                    label={tr('Cancel')}
                                                    type="transparent"
                                                    onClick={() => {
                                                        setAddNote(false);
                                                    }}
                                                />
                                                <Button
                                                    size="small"
                                                    label={tr('Add')}
                                                    isLoading={loadingNotes}
                                                    icon={loadingNotes && 'load'}
                                                    disabled={!note}
                                                    onClick={() => {
                                                        actions.addNote();
                                                        setAddNote(false);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {notes?.map((currentNote) => (
                                        <React.Fragment key={currentNote.id}>
                                            {currentNote.id === editNote && (
                                                <div className="lnuser__note__edit">
                                                    <Input
                                                        contenteditable
                                                        type="textarea"
                                                        value={editNoteContent}
                                                        onChange={(e) => setEditNoteContent(e.target.value)}
                                                    />
                                                    <Button
                                                        size="small"
                                                        label={tr('Update')}
                                                        isLoading={loadingNotes}
                                                        icon={loadingNotes && 'load'}
                                                        onClick={() => {
                                                            actions.updateNote(editNote, editNoteContent);
                                                            setEditNote(null);
                                                        }}
                                                    />
                                                </div>
                                            )}

                                            {currentNote.id !== editNote && (
                                                <div className="lnuser__note__container" key={currentNote.id}>
                                                    <div className="lnuser__note__display">
                                                        {utils.nl2br(currentNote.content)}
                                                    </div>
                                                    <div className="lnuser__note__date">
                                                        {moment(currentNote.created_at).format('LLL')}
                                                    </div>
                                                    <Dropdown
                                                        btnFaceIcon="menu"
                                                        onClick={(e) => e.stopPropagation()}
                                                        onDoubleClick={(e) => e.stopPropagation()}
                                                    >
                                                        <Dropdown.Menu>
                                                            <Dropdown.ButtonItem
                                                                label={tr('Edit')}
                                                                onClick={() => {
                                                                    setEditNote(currentNote.id);
                                                                    setEditNoteContent(currentNote.content);
                                                                }}
                                                            />
                                                            <Dropdown.ConfirmButtonItem
                                                                label={tr('Delete')}
                                                                placement="left-center"
                                                                confirmLabel={tr(
                                                                    'Are you sure you want to delete this note?'
                                                                )}
                                                                onConfirm={() => actions.deleteNote(currentNote)}
                                                            />
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                            <div className="lnuser__sidebar__section">
                                <div className="lnuser__reminder">
                                    <h5>{tr('Reminder')}</h5>
                                    {lnUser?.remind_at && (
                                        <div className="lnuser__reminder__set">
                                            <div className="lnuser__reminder__set__content">
                                                <Icon icon="clock" />
                                                {lnUser?.remind_at && moment(lnUser?.remind_at).format('LL')}
                                            </div>

                                            <Button
                                                size="mini"
                                                icon="trash-can"
                                                type="link"
                                                onClick={() => {
                                                    setRemind({ type: 'remove', date: new Date() });
                                                }}
                                            />
                                        </div>
                                    )}
                                    <Dropdown
                                        btnFaceIcon={lnUser?.remind_at ? 'pen' : 'clock'}
                                        btnFace={lnUser?.remind_at ? 'link' : 'secondary-dark'}
                                        btnFaceIconSize={lnUser?.remind_at ? 14 : 24}
                                        label={lnUser?.remind_at ? tr('Edit reminder') : tr('Remind me')}
                                        btnIsLoading={loadingRemind === lnUser?.id}
                                        ref={dropdownMenuRef}
                                        onClick={(e) => e.stopPropagation()}
                                        onDoubleClick={(e) => e.stopPropagation()}
                                    >
                                        <Dropdown.Menu>
                                            <Dropdown.ButtonItem
                                                label={tr('In 1 day')}
                                                onClick={() => {
                                                    setRemind({ type: '1day', date: new Date() });
                                                }}
                                            />
                                            <Dropdown.ButtonItem
                                                label={tr('In 3 days')}
                                                onClick={() => {
                                                    setRemind({ type: '3days', date: new Date() });
                                                }}
                                            />
                                            <Dropdown.ButtonItem
                                                label={tr('In 1 week')}
                                                onClick={() => {
                                                    setRemind({ type: '1week', date: new Date() });
                                                }}
                                            />
                                            <li className="dropdown__menu__item">
                                                <DatePicker
                                                    selected={remind.date}
                                                    minDate={new Date()}
                                                    onChange={(date) => {
                                                        setRemind({
                                                            type: 'custom',
                                                            date: moment(date).startOf('day').toDate(),
                                                        });
                                                        dropdownMenuRef?.current?.handleClickOutside();
                                                    }}
                                                    customInput={<Dropdown.ButtonItem label={tr('Pick a date')} />}
                                                />
                                            </li>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            {!lnUser?.is_groupchat && (
                                <div className="lnuser__sidebar__section">
                                    <div className="lnuser__board">
                                        <div className="lnuser__board__header">
                                            <h5>{tr('Pipeline')}</h5>
                                            {lnUser?.board && (
                                                <span>
                                                    <Tooltip direction="nw" text={tr('Open Pipeline')}>
                                                        <Link
                                                            className="btn btn--transparent btn--open-board btn--no-label btn--icon btn--small"
                                                            onClick={() => {
                                                                modalRef?.current.close();
                                                                setSelectedBoardId(lnUser?.board);
                                                            }}
                                                            to="/board"
                                                        >
                                                            <Icon icon="board-arrow-top" />
                                                        </Link>
                                                    </Tooltip>
                                                    <Tooltip direction="nw" text={tr('Remove from Pipeline')}>
                                                        <Button
                                                            size="small"
                                                            type="transparent"
                                                            icon="trash-can"
                                                            isLoading={updateConnectionLoading === 'delete'}
                                                            disabled={!!updateConnectionLoading}
                                                            onClick={() => lnUsersActions.removeboard(lnUser)}
                                                        />
                                                    </Tooltip>
                                                </span>
                                            )}
                                        </div>
                                        {lnUser && (
                                            <MoveBoard
                                                dir="s"
                                                originBtnIcon
                                                originBtnType="secondary-dark"
                                                lnUser={lnUser}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="lnuser__sidebar__section">
                                <div className="lnuser__labels">
                                    {lnUser && (
                                        <Labels
                                            isClosable
                                            placement="left-center"
                                            context={lnUser?.labels?.length === 0 ? 'modal-nolabel' : 'modal'}
                                            lnUser={lnUser}
                                            updateLnUser
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="lnuser__sidebar__section">
                                <div className="lnuser__actions">
                                    {currentConversation && !lnUser?.is_archived && (
                                        <>
                                            <div className="lnuser__actions__header">
                                                <h5>{tr('Inbox')}</h5>
                                            </div>
                                            <div className="lnuser__actions__list">
                                                <Button
                                                    type="secondary-dark"
                                                    icon={currentConversation?.is_read ? 'mail-opened' : 'mail'}
                                                    label={
                                                        currentConversation?.is_read
                                                            ? tr('Mark as unread')
                                                            : tr('Mark as read')
                                                    }
                                                    isLoading={
                                                        updateConversationLoading === 'read' ||
                                                        updateConversationLoading === 'unread'
                                                    }
                                                    disabled={!!updateConversationLoading}
                                                    onClick={() =>
                                                        linkedInActions.updateConversation(
                                                            currentConversation,
                                                            currentConversation?.is_read ? 'unread' : 'read'
                                                        )
                                                    }
                                                />
                                                <Button
                                                    type="secondary-dark"
                                                    icon={currentConversation?.is_archived ? 'inbox' : 'inbox-archive'}
                                                    label={
                                                        currentConversation?.is_archived
                                                            ? tr('Unarchive conversation')
                                                            : tr('Archive conversation')
                                                    }
                                                    isLoading={
                                                        updateConversationLoading === 'archive' ||
                                                        updateConversationLoading === 'unarchive'
                                                    }
                                                    disabled={!!updateConversationLoading}
                                                    onClick={() => {
                                                        // We archive all the user conversations
                                                        if (!currentConversation?.is_archived) {
                                                            lnUserConversations?.forEach((conv) => {
                                                                if (conv.id !== currentConversation.id) {
                                                                    linkedInActions.updateConversation(conv, 'archive');
                                                                }
                                                            });
                                                        }
                                                        linkedInActions.updateConversation(
                                                            currentConversation,
                                                            currentConversation?.is_archived ? 'unarchive' : 'archive'
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}
                                    {webhooks && webhooks.count >= 1 && (
                                        <div className="lnuser__actions">
                                            <div className="lnuser__actions__header">
                                                <h5>{tr('Integration')}</h5>
                                            </div>
                                            <div className="lnuser__actions__list">
                                                {webhooks && webhooks.count > 1 && (
                                                    <div className="flex gap--10">
                                                        <Select
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            placeholder={tr('Send user to')}
                                                            onChange={(selected) => {
                                                                setSelectedWebhook(selected);
                                                            }}
                                                            isDisabled={webhooks?.items.length === 0}
                                                            options={webhooks?.items?.map((current) => ({
                                                                value: current.id,
                                                                name: current.name,
                                                                label: current.name,
                                                            }))}
                                                        />
                                                        <Button
                                                            label={tr('Send')}
                                                            isLoading={sending}
                                                            onClick={() =>
                                                                actions.sendWebhook(lnUser, selectedWebhook.value)
                                                            }
                                                        />
                                                    </div>
                                                )}
                                                {webhooks && webhooks.count === 1 && (
                                                    <div className="flex">
                                                        <Button
                                                            label={tr('Send to {{name}}', {
                                                                name: webhooks?.items[0].name,
                                                            })}
                                                            isLoading={sending}
                                                            onClick={() =>
                                                                actions.sendWebhook(lnUser, webhooks?.items[0].id)
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {!lnUser?.is_groupchat && (
                                        <>
                                            <div className="lnuser__actions__header">
                                                <h5>{tr('Manage')}</h5>
                                            </div>

                                            {lnUser.webhook_name && (
                                                <div>
                                                    {tr('Last sent to {{name}} on {{date}}', {
                                                        name: lnUser.webhook_name,
                                                        date: moment(lnUser.webhook_sent_at).format('LLL'),
                                                    })}
                                                </div>
                                            )}
                                            <div className="lnuser__actions__list">
                                                <Button
                                                    type="secondary-dark"
                                                    icon={lnUser.is_processed ? 'unarchive-circle' : 'archive-circle'}
                                                    label={
                                                        lnUser.is_processed
                                                            ? tr('Unarchive Member')
                                                            : tr('Archive Member')
                                                    }
                                                    isLoading={updateConnectionLoading === 'makeprocessed'}
                                                    disabled={!!updateConnectionLoading}
                                                    onClick={() =>
                                                        lnUser.is_processed
                                                            ? lnUsersActions.unprocessLead(lnUser.id)
                                                            : lnUsersActions.processLead(lnUser.id)
                                                    }
                                                />
                                                {currentConversation && (
                                                    <>
                                                        {!lnUser?.is_archived && (
                                                            <Button
                                                                type="secondary-dark"
                                                                icon={
                                                                    currentConversation?.is_starred
                                                                        ? 'star-remove'
                                                                        : 'star'
                                                                }
                                                                label={
                                                                    currentConversation?.is_starred
                                                                        ? tr('Unstar')
                                                                        : tr('Star')
                                                                }
                                                                isLoading={
                                                                    updateConversationLoading === 'star' ||
                                                                    updateConversationLoading === 'unstar'
                                                                }
                                                                disabled={!!updateConversationLoading}
                                                                onClick={() =>
                                                                    linkedInActions.updateConversation(
                                                                        currentConversation,
                                                                        currentConversation?.is_starred
                                                                            ? 'unstar'
                                                                            : 'star'
                                                                    )
                                                                }
                                                            />
                                                        )}

                                                        <ConfirmButton
                                                            type="secondary-dark"
                                                            icon="message-remove"
                                                            label={tr('Delete conversation')}
                                                            confirmLabel={tr(
                                                                'Are you sure you want to permanently delete this conversation?'
                                                            )}
                                                            isLoading={updateConversationLoading === 'delete'}
                                                            disabled={!!updateConversationLoading}
                                                            onConfirm={() =>
                                                                linkedInActions.deleteConversation(currentConversation)
                                                            }
                                                        />
                                                    </>
                                                )}
                                                {lnUser?.is_archived && (
                                                    <Button
                                                        type="secondary-dark"
                                                        icon="show"
                                                        label={tr('Unhide from Kanbox')}
                                                        isLoading={updateConnectionLoading === 'delete'}
                                                        disabled={!!updateConnectionLoading}
                                                        onClick={() => actions.unarchiveUser(lnUser.id)}
                                                    />
                                                )}
                                                {!lnUser?.is_archived && (
                                                    <Button
                                                        type="secondary-dark"
                                                        icon="hide"
                                                        label={tr('Hide from Kanbox')}
                                                        isLoading={updateConnectionLoading === 'delete'}
                                                        disabled={!!updateConnectionLoading}
                                                        onClick={() => actions.archiveUser(lnUser.id)}
                                                    />
                                                )}
                                                {lnUser?.is_connection && (
                                                    <ConfirmButton
                                                        type="secondary-dark"
                                                        icon="user-remove"
                                                        label={tr('Remove from my connections')}
                                                        confirmLabel={tr(
                                                            'Are you sure you want to remove this member from your connections?'
                                                        )}
                                                        isLoading={updateConnectionLoading === 'remove'}
                                                        disabled={!!updateConnectionLoading}
                                                        onConfirm={() => linkedInActions.removeConnection()}
                                                    />
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default LnUser;
