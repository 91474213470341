import { useRef, useEffect, useState, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import cl from 'classnames';

import debounce from 'lodash.debounce';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { tr } from '../../common/locale';
import Tooltip from '../../components/Tooltip';
import Icon from '../../components/Icon';
import utils from '../../common/utils';
import Dropdown from '../../components/Dropdown';
import Flash from '../../components/Flash';
import Button from '../../components/Button';
import useLeadsActions from '../../_actions/leads.actions';
import useUserActions from '../../_actions/user.actions';
import useLinkedinActions from '../../_actions/linkedin.actions';
import Switch from '../../components/Switch';
import Avatar from '../../components/Avatar';
import {
    searchesState,
    loadingState,
    scrapSearchState,
    filterArchiveState,
    createListState,
    eventUrlState,
    postUrlState,
    filterSearchState,
    scrapeEventStatusState,
    scrapePostStatusState,
    scrapLikesState,
    scrapCommentsState,
    filterOwnerState,
} from '../../_states/leads.states';
import { notifsState, userState, teamUsersState } from '../../_states/user.states';
import scrapeImg from '../../images/scrape-search-results.webp';
import TooltipPopover from '../../components/TooltipPopover';
import Modal from '../../components/Modal';
import Input from '../../components/Input';
import ImportLeads from './ImportLeads';

function Searches() {
    const actions = useLeadsActions();
    const linkedinActions = useLinkedinActions();
    const userActions = useUserActions();
    const scrollRef = useRef();
    const fileRef = useRef();
    const createListRef = useRef();
    const urlProfilesRef = useRef();
    const connectionsRef = useRef();
    const attendeesRef = useRef();
    const importLeadsRef = useRef();
    const postRef = useRef();
    const teamUsers = useRecoilValue(teamUsersState);
    const editModalRef = useRef();
    const [name, setName] = useState('');
    const [csvProfiles, setCsvProfiles] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [listName, setListName] = useState('');
    const [eventUrl, setEventUrl] = useRecoilState(eventUrlState);
    const [scrapeEventStatus, setScrapeEventStatus] = useRecoilState(scrapeEventStatusState);
    const [postUrl, setPostUrl] = useRecoilState(postUrlState);
    const [scrapLikes, setScrapLikes] = useRecoilState(scrapLikesState);
    const [scrapComments, setScrapComments] = useRecoilState(scrapCommentsState);
    const [scrapePostStatus, setScrapePostStatus] = useRecoilState(scrapePostStatusState);
    const [editSearch, setEditSearch] = useState(null);
    const [dragActive, setDragActive] = useState(false);

    const [filterOwner, setFilterOwner] = useRecoilState(filterOwnerState);
    const location = useLocation();
    const scrapingSearch = useRecoilValue(scrapSearchState);
    const notifs = useRecoilValue(notifsState);
    const user = useRecoilValue(userState);
    const loading = useRecoilValue(loadingState);
    const searches = useRecoilValue(searchesState);
    const createList = useRecoilValue(createListState);
    const [filterSearch, setFilterSearch] = useRecoilState(filterSearchState);
    const [filterArchive, setFilterArchive] = useRecoilState(filterArchiveState);
    const navigate = useNavigate();
    const searchFunc = (value) => {
        setFilterSearch(value);
    };
    const isTeamShared = user?.team.users_count > 1 && user?.team.is_shared_leads;
    const debouncedSearch = useCallback(debounce(searchFunc, 300), []);
    useEffect(() => {
        userActions.updateBrowsing();
    }, []);
    useEffect(() => {
        if (isTeamShared) {
            userActions.listTeam(true);
        }
    }, [user]);
    useEffect(() => {
        if (!createList) {
            urlProfilesRef.current.close();
            connectionsRef.current.close();
            createListRef.current.close();
            setCsvProfiles([]);
        }
    }, [createList]);
    useEffect(() => {
        if (eventUrl === null) {
            attendeesRef.current.close();
        } else {
            attendeesRef.current.open();
        }
    }, [eventUrl]);
    useEffect(() => {
        if (scrapeEventStatus) {
            attendeesRef.current.setLoading(false);
        }
    }, [scrapeEventStatus]);

    useEffect(() => {
        if (scrapePostStatus) {
            postRef.current.setLoading(false);
        }
    }, [scrapePostStatus]);
    useEffect(() => {
        if (postUrl === null) {
            postRef.current.close();
        } else {
            postRef.current.open();
        }
    }, [postUrl]);
    useEffect(() => {
        actions.list(true);

        if (scrollRef?.current) {
            scrollRef.current.pageLoaded = 0;
            scrollRef.current.el.scrollTop = 0;
        }
    }, [location.state, filterArchive, filterSearch, filterOwner]);
    useEffect(() => {
        if (!editSearch && editModalRef.current) {
            editModalRef.current.close();
            setName('');
        }
    }, [editSearch]);

    const onFileChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            // eslint-disable-next-line prefer-destructuring
            files = e.dataTransfer.files;
        } else if (e.target) {
            // eslint-disable-next-line prefer-destructuring
            files = e.target.files;
        }
        setDragActive(false);
        const reader = new FileReader();
        reader.onload = () => {
            const result = [];
            const urlRegex = /(linkedin\.com\/i[^/]+(\/[\w-%]+)+)/g;
            const parsed = reader.result.match(urlRegex);
            if (parsed) {
                parsed.forEach((item) => {
                    if (item.indexOf('linkedin.com/in/') >= 0) {
                        const publicId = item.slice(item.indexOf('linkedin.com/in/') + 16);
                        if (publicId && result.indexOf(publicId) === -1) {
                            result.push(publicId);
                        }
                    }
                });
            }
            setCsvProfiles(result);
        };
        reader.readAsText(files[0]);
    };

    const handleFileDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };
    const hasMore = !searches || searches.count > searches.items.length;

    const fakeLoader = [];
    for (let i = 0; i <= 20; i++) {
        fakeLoader.push(
            <div key={i} className="list__fake list__fake--searches">
                <div className="list__col col-origin-icon">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col--fill scrape-list">
                    <div className="list__fake__item" />
                </div>
                {isTeamShared && (
                    <div className="list__col team-owner">
                        <div className="list__fake__item" />
                    </div>
                )}

                <div className="list__col">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col col-date">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col col-importation">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col list__col--action" />
            </div>
        );
    }

    const searchStatus = (search) => {
        if (
            user?.plan?.product === 'TRIAL' &&
            user?.plan?.has_trial_export_limit &&
            search.is_processing &&
            notifs?.export_leads >= 1000
        ) {
            return (
                <Tooltip
                    wrap
                    direction="w"
                    text={tr("You've reached the trial limit of 1000 imports. Please upgrade your plan.")}
                >
                    <div className="quota subscription">
                        <Icon size={11} icon="pause" />
                        <span>{tr('Trial quota')}</span>
                    </div>
                </Tooltip>
            );
        }
        if (user?.plan?.product === 'CONNECT' && search.is_processing) {
            return (
                <Tooltip wrap direction="w" text={tr('Please upgrade your plan to be able to import lists.')}>
                    <div className="quota subscription">
                        <Icon size={11} icon="pause" />
                        <span>{tr('Upgrade')}</span>
                    </div>
                </Tooltip>
            );
        }
        if (search.is_processing && search.is_salesnav && user?.quotas?.daily_import_salesnav <= 0) {
            return (
                <Tooltip wrap direction="w" text={tr('Daily quota reached. The import will resume tomorrow.')}>
                    <div className="quota daily">
                        <Icon size={11} icon="pause" />
                        <span>{tr('Paused (Quota)')}</span>
                    </div>
                </Tooltip>
            );
        }
        if (search.is_processing && !search.is_salesnav && user?.quotas?.daily_import_standard <= 0) {
            return (
                <Tooltip wrap direction="w" text={tr('Daily quota reached. The import will resume tomorrow.')}>
                    <div className="quota daily">
                        <Icon size={11} icon="pause" />
                        <span>{tr('Paused (Quota)')}</span>
                    </div>
                </Tooltip>
            );
        }
        if (search.is_processing && search.is_paused) {
            return (
                <Tooltip wrap direction="w" text={tr('The search is paused.')}>
                    <div className="quota queued">
                        <Icon size={11} icon="pause" />
                        <span>{tr('Paused')}</span>
                    </div>
                </Tooltip>
            );
        }
        if (search.is_processing && scrapingSearch && scrapingSearch.id !== search.id) {
            return (
                <Tooltip
                    wrap
                    direction="w"
                    text={tr('Another search is being scrapped. The import will resume after it.')}
                >
                    <div className="quota queued">
                        <Icon size={11} icon="pause" />
                        <span>{tr('Queued')}</span>
                    </div>
                </Tooltip>
            );
        }
        if (search.is_processing) {
            return (
                <Tooltip wrap direction="w" text={tr('Importing')}>
                    <Icon size={16} isProcessing icon="cog-alt" />
                    <span className="import-count">
                        {search.offset > search.linkedin_count ? search.total_count : search.offset} /{' '}
                        {search.linkedin_count}
                    </span>
                </Tooltip>
            );
        }
        return 'Imported';
    };

    const searchTypeLabel = (currentSearch) => {
        if (currentSearch.is_salesnav) {
            return tr('Extracted from LinkedIn Sales Navigator');
        }
        if (currentSearch.is_free_search) {
            return tr('Extracted from LinkedIn');
        }
        if (currentSearch.profiles) {
            return tr('Imported from CSV');
        }
        if (currentSearch.linkedin_ids && currentSearch.search_url?.indexOf('https://www.linkedin.com/posts/') !== -1) {
            return tr('Imported from Post');
        }
        if (currentSearch.linkedin_ids) {
            return tr('Imported from Connections');
        }
        return tr('List');
    };
    return (
        <div className="content">
            <div className="content__body lead-lists">
                {user?.plan.product === 'CONNECT' && (
                    <div className="service-message service-message--info">
                        <div>
                            {tr('The Leads importer is not available with your subscription.')}
                            &nbsp;
                            {user?.plan.owner === user.id && !user?.appsumo_code1 && (
                                <Link to="/subscription">{tr('Click here to upgrade.')}</Link>
                            )}
                            {user?.plan.owner === user.id &&
                                user?.appsumo_code1 &&
                                tr('You have an Appsumo plan 1 subscription. You need the plan 2.')}
                        </div>
                    </div>
                )}
                <div className={cl('lead-lists__header')}>
                    <div className="lead-lists__header__title">
                        <h1 className="title">{tr('Lists of Leads')}</h1>
                        <div className={cl('lead-lists__header__select')}>
                            <Dropdown
                                iconRight
                                btnFace="primary"
                                label={tr('New Import')}
                                onClick={(e) => e.stopPropagation()}
                                onDoubleClick={(e) => e.stopPropagation()}
                            >
                                <Dropdown.Menu direction="se">
                                    <Dropdown.ButtonItem
                                        btnLabel={tr('From Sales Navigator')}
                                        btnIcon="list-sales-navigator"
                                        disabled={!user?.has_sales_nav}
                                        onClick={() =>
                                            window.open('https://www.linkedin.com/sales/search/people', '_blank')
                                        }
                                    />
                                    <Dropdown.ButtonItem
                                        btnLabel={tr('From Linkedin search')}
                                        btnIcon="list-linkedin"
                                        onClick={() =>
                                            window.open('https://www.linkedin.com/search/results/people', '_blank')
                                        }
                                    />
                                    <Dropdown.ButtonItem
                                        btnLabel={tr('From my connections')}
                                        btnIcon="user"
                                        onClick={() => connectionsRef.current.open()}
                                    />
                                    <Dropdown.ButtonItem
                                        btnLabel={tr('From a list of Linkedin profile urls')}
                                        btnIcon="link-square"
                                        onClick={() => urlProfilesRef.current.open()}
                                    />
                                    <Dropdown.ButtonItem
                                        btnLabel={tr('From a CSV file of leads')}
                                        btnIcon="csv-file"
                                        onClick={() => importLeadsRef.current.open()}
                                    />
                                    <Dropdown.ButtonItem
                                        btnLabel={tr('Attendees of a Linkedin event')}
                                        btnIcon="agenda"
                                        onClick={() => {
                                            setEventUrl('');
                                        }}
                                    />
                                    <Dropdown.ButtonItem
                                        btnLabel={tr('Contributors of a Linkedin post')}
                                        btnIcon="post"
                                        onClick={() => {
                                            setPostUrl('');
                                            setCsvProfiles([]);
                                        }}
                                    />
                                    <Dropdown.ButtonItem
                                        btnLabel={tr('Create an empty list')}
                                        btnIcon="list-add"
                                        onClick={() => {
                                            createListRef.current.open();
                                        }}
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <ImportLeads importLeadsRef={importLeadsRef} />
                    <Modal
                        ref={createListRef}
                        disabled={!listName}
                        validateCb={() => {
                            actions.createSearchFromList(listName, []);
                        }}
                        closeCb={() => {
                            setListName('');
                        }}
                        title={tr('Create an empty list')}
                        labelValidate={tr('Create')}
                    >
                        <Input
                            label={tr('Name your list')}
                            placeholder={tr('Type in a name')}
                            value={listName}
                            onChange={(e) => setListName(e.target.value)}
                        />
                    </Modal>
                    <Modal
                        ref={urlProfilesRef}
                        disabled={!listName || csvProfiles.length === 0}
                        validateCb={() => {
                            actions.createSearchFromList(listName, csvProfiles);
                        }}
                        closeCb={() => {
                            setDragActive(false);
                            setListName('');
                        }}
                        title={tr('Import from Linkedin Profile Urls')}
                        labelValidate={tr('Launch import')}
                    >
                        <p className="mb--15">
                            {tr(
                                'Upload a CSV containing a list of Linkedin Profile Urls (just that). If you launch the import, it will scrape these profiles using the daily Linkedin import quota (max 80 users / day). So, if you want to scrape more than 80 profiles, it will take several days.'
                            )}
                        </p>
                        <Input
                            label={tr('Name your list')}
                            placeholder={tr('Type in a name')}
                            value={listName}
                            onChange={(e) => setListName(e.target.value)}
                        />
                        <div
                            className={`btn--dropzone-container mt--15 mb--20 ${dragActive ? 'active' : ''}`}
                            onDragEnter={handleFileDrag}
                            onDragLeave={handleFileDrag}
                            onDragOver={handleFileDrag}
                            onDrop={onFileChange}
                        >
                            <input
                                style={{ display: 'none' }}
                                ref={fileRef}
                                type="file"
                                accept=".csv"
                                onChange={onFileChange}
                            />
                            <Button
                                type="dropzone"
                                label={tr('+ Drag a file or click to choose a TXT or CSV file...')}
                                onClick={() => {
                                    fileRef.current.click();
                                }}
                            />
                        </div>
                        {csvProfiles.length > 0 && (
                            <p className="bleu">
                                <strong>
                                    {tr('{{count}} detected profiles to be scraped.', { count: csvProfiles.length })}
                                </strong>
                            </p>
                        )}
                    </Modal>
                    <Modal
                        ref={connectionsRef}
                        validateCb={() => {
                            actions.createSearchFromConnections(listName);
                        }}
                        closeCb={() => {
                            setListName('');
                        }}
                        title={tr('Import from my connections')}
                        labelValidate={tr('Launch import')}
                    >
                        <p className="mb--15">
                            {tr(
                                'It will re-import all of your 1st degree connections with all the information. It will allow you to have advanced filters to organize your network.'
                            )}
                        </p>
                        <Input
                            label={tr('Name your list')}
                            placeholder={tr('Type in a name')}
                            value={listName}
                            onChange={(e) => setListName(e.target.value)}
                        />
                    </Modal>
                    <Modal
                        ref={attendeesRef}
                        validateCb={() => {
                            linkedinActions.scrapeEvent(listName, eventUrl);
                        }}
                        closeCb={() => {
                            setScrapeEventStatus(false);
                            setEventUrl(null);
                            setListName('');
                        }}
                        disabled={!eventUrl || !listName}
                        title={tr('Import attendees of a Linkedin event')}
                        labelValidate={tr('Launch import')}
                    >
                        <Flash color="yellow" className="mb--20">
                            {tr('You must attend the event to be able to scrape the attendees!')}
                        </Flash>
                        <Input
                            className="mb--20"
                            label={tr('Name your list')}
                            placeholder={tr('Type in a name')}
                            value={listName}
                            onChange={(e) => setListName(e.target.value)}
                        />
                        <p className="mt--30 mb--15">
                            <strong>
                                {tr('Copy / paste the url of the event. It will scrape all the attendees.')}
                            </strong>
                        </p>

                        <Input
                            label={tr('Linkedin event URL')}
                            placeholder={tr('Copy / paste the url')}
                            value={eventUrl}
                            onChange={(e) => setEventUrl(e.target.value)}
                        />

                        {scrapeEventStatus === 'wrongurl' && <Flash color="red">{tr('The url is invalid')}</Flash>}
                        {scrapeEventStatus === 'scraperror' && (
                            <Flash color="red">{tr('Could not process attendees list')}</Flash>
                        )}
                    </Modal>
                    <Modal
                        ref={postRef}
                        validateCb={() => {
                            linkedinActions.scrapePost(listName, postUrl);
                        }}
                        closeCb={() => {
                            setScrapePostStatus(false);
                            setPostUrl(null);
                            setListName('');
                        }}
                        disabled={!postUrl || !listName || (!scrapComments && !scrapLikes) || scrapePostStatus}
                        title={tr('Import contributors of a Linkedin post (likes and comments)')}
                        labelValidate={tr('Launch import')}
                    >
                        <Input
                            className="mb--20"
                            label={tr('Name your list')}
                            placeholder={tr('Type in a name')}
                            value={listName}
                            onChange={(e) => setListName(e.target.value)}
                        />
                        <p className="mt--30 mb--15">
                            <strong>
                                {tr(
                                    'Copy / paste the url of the post. It will scrape the contributors (members who liked or commented the post).'
                                )}
                            </strong>
                        </p>

                        <Input
                            label={tr('Linkedin post URL')}
                            placeholder={tr('Copy / paste the url')}
                            value={eventUrl}
                            onChange={(e) => {
                                setPostUrl(e.target.value);
                                setScrapePostStatus(false);
                            }}
                        />
                        <div className="mt--20">
                            <Switch
                                text={tr('Import from Likes')}
                                id="exportFromLike"
                                checked={scrapLikes}
                                onChange={(val) => setScrapLikes(val)}
                            />
                        </div>
                        <div className="mt--20">
                            <Switch
                                text={tr('Import from Comments')}
                                id="exportFromComments"
                                checked={scrapComments}
                                onChange={(val) => setScrapComments(val)}
                            />
                        </div>
                        <div className="mt--20">
                            {scrapePostStatus === 'wrongurl' && <Flash color="red">{tr('The url is invalid')}</Flash>}
                            {scrapePostStatus === 'scraperror' && (
                                <Flash color="red">{tr('Could not process contributors list')}</Flash>
                            )}
                        </div>
                    </Modal>
                    {teamUsers && isTeamShared && (
                        <div className={cl('lead-lists__header__select')}>
                            <Dropdown
                                iconRight
                                label={
                                    filterOwner ? `${filterOwner.firstname} ${filterOwner.lastname}` : tr('Team lists')
                                }
                                onClick={(e) => e.stopPropagation()}
                                onDoubleClick={(e) => e.stopPropagation()}
                            >
                                <Dropdown.Menu direction="se">
                                    <Dropdown.ButtonItem
                                        btnLabel={tr('Team lists')}
                                        onClick={() => setFilterOwner(null)}
                                    />
                                    {teamUsers?.items.map((currentUser) => (
                                        <Dropdown.ButtonItem
                                            key={currentUser.id}
                                            btnLabel={`${currentUser.firstname} ${currentUser.lastname}`}
                                            onClick={() => setFilterOwner(currentUser)}
                                        />
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    )}
                    <div className={cl('lead-lists__header__select')}>
                        <Dropdown
                            iconRight
                            label={filterArchive ? tr('Archived lists') : tr('Active lists')}
                            onClick={(e) => e.stopPropagation()}
                            onDoubleClick={(e) => e.stopPropagation()}
                        >
                            <Dropdown.Menu direction="se">
                                <Dropdown.ButtonItem
                                    btnLabel={tr('Active lists')}
                                    active={!filterArchive}
                                    onClick={() => setFilterArchive(false)}
                                />
                                <Dropdown.ButtonItem
                                    btnLabel={tr('Archived lists')}
                                    active={filterArchive}
                                    onClick={() => setFilterArchive(true)}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className={cl('lead-lists__header__search')}>
                        <Input
                            isFilter
                            isNoBorder
                            icon="search"
                            value={searchText}
                            onChange={(e) => {
                                setSearchText(e.target.value);
                                debouncedSearch(e.target.value);
                            }}
                            placeholder={tr('Search by name')}
                            type="search"
                            isFocusedDefault={false}
                        />
                    </div>
                </div>
                <div className={cl('lead-lists__header-info')}>
                    <h4 className="counter">
                        <Tooltip direction="s" text={tr('Archived lists included')}>
                            {tr('Total imported lists:')} {notifs?.export_lists}
                        </Tooltip>
                    </h4>
                    <h4 className="counter">
                        <Tooltip direction="s" text={tr('Archived leads included')}>
                            {tr('Total imported leads:')} {notifs?.export_leads}
                        </Tooltip>
                    </h4>
                    <div className="flashcontainer">
                        <Flash color="blue">
                            <strong className="mr--10">{tr('Remaining credits -')}</strong>
                            <span>
                                {tr('Email credits: {{enrichment}}', {
                                    enrichment: user?.team?.email_credits,
                                })}
                            </span>
                        </Flash>

                        <Flash color="blue">
                            <strong className="mr--10">{tr('Daily quotas -')}</strong>
                            <span>
                                {tr('Linkedin : {{quota}} / {{quotaconf}}', {
                                    quota: user?.quotas?.daily_import_standard,
                                    quotaconf: user?.quotas?.daily_import_standard_conf,
                                })}{' '}
                                {user?.has_sales_nav
                                    ? tr(' - Sales Navigator: {{quota}} / {{quotaconf}}', {
                                          quota: user?.quotas?.daily_import_salesnav,
                                          quotaconf: user?.quotas?.daily_import_salesnav_conf,
                                      })
                                    : ''}
                            </span>
                        </Flash>
                    </div>
                </div>
                {searches && searches?.items.length > 0 && (
                    <div className="list__header">
                        <div className="list__cols">
                            <div className="list__col col-origin-icon" />
                            <div className="list__col list__col--fill scrape-list">{tr('Name')}</div>
                            {isTeamShared && <div className="list__col  team-owner">{tr('Owner')}</div>}
                            <div className="list__col">{tr('Members')}</div>
                            <div className="list__col col-overflow">
                                <Tooltip wrap text={tr('Members existing in other lists')} direction="s">
                                    {tr('Duplicates *')}
                                </Tooltip>
                            </div>
                            <div className="list__col">{tr('Match')}</div>
                            <div className="list__col col-date">{tr('Date')}</div>
                            <div className="list__col" title={tr('Enriched emails')}>
                                {tr('Enriched emails')}
                            </div>
                            <div className="list__col col-importation">{tr('Status')}</div>
                            <div className="list__col list__col--action" />
                        </div>
                    </div>
                )}
                <div
                    id="connectionsContent"
                    className={`connections--list list--bg ${
                        loading || (searches && searches?.items.length > 0) ? '' : 'list--empty'
                    }`}
                >
                    {loading && (!searches || searches?.items.length === 0) && fakeLoader}
                    {searches && searches?.items.length > 0 ? (
                        <InfiniteScroll
                            dataLength={searches?.items.length || 0}
                            next={actions.list}
                            className="list list--clickable"
                            hasMore={hasMore}
                            loader={fakeLoader}
                            ref={scrollRef}
                            scrollableTarget="connectionsContent"
                        >
                            <div className="list__item list__item--all-leads" key="all">
                                <div
                                    className="list__row"
                                    tabIndex="-1"
                                    role="button"
                                    onClick={() => {
                                        navigate('/searches/all');
                                    }}
                                    onKeyDown={() => null}
                                >
                                    <div className="list__cols">
                                        <div className="list__col col-origin-icon">
                                            <Tooltip text={tr('All leads')}>
                                                <Icon size={24} icon="notebook" />
                                            </Tooltip>
                                        </div>
                                        <div className="list__col list__col--fill scrape-list">
                                            <span className="scrape-list-name">{tr('All leads')}</span>
                                        </div>
                                        {isTeamShared && <div className="list__col team-owner">All</div>}
                                        <div className="list__col green" />
                                        <div className="list__col" />
                                        <div className="list__col col-overflow" />
                                        <div className="list__col col-date" />
                                        <div className="list__col col-overflow" />

                                        <div className="list__col col-overflow " />

                                        <div className="list__col list__col--action" />
                                    </div>
                                </div>
                            </div>
                            {searches &&
                                searches.items.map((search, index) => (
                                    <div
                                        className={`list__item ${filterArchive ? 'archived--item' : ''}`}
                                        key={search.id}
                                    >
                                        <div
                                            className="list__row"
                                            tabIndex={index}
                                            role="button"
                                            onClick={() => {
                                                navigate(`/searches/${search.id}`);
                                            }}
                                            onKeyDown={() => null}
                                        >
                                            <div className="list__cols">
                                                <div className="list__col col-origin-icon">
                                                    <Tooltip text={searchTypeLabel(search)}>
                                                        <Icon size={24} icon={utils.searchTypeIcon(search)} />
                                                    </Tooltip>
                                                </div>
                                                <div className="list__col list__col--fill scrape-list">
                                                    <span className="scrape-list-name">{search.name}</span>
                                                    {search.is_enrich && (
                                                        <TooltipPopover text={tr('Enriched list')}>
                                                            <span className="enriched-profile">
                                                                <Icon size={9} icon="check" />
                                                            </span>
                                                        </TooltipPopover>
                                                    )}
                                                </div>
                                                {isTeamShared && (
                                                    <div className="list__col team-owner">
                                                        <div className="team-avatar">
                                                            <Avatar
                                                                className="avatar"
                                                                src={search.user.picture}
                                                                alt={`${search.user.firstname} ${search.user.lastname}`}
                                                            />
                                                            <span className="ellipsis">
                                                                {search.user.firstname}&nbsp;{search.user.lastname}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="list__col green">
                                                    <strong>
                                                        {search.is_processing
                                                            ? `${search.total_count} / ${search.linkedin_count}`
                                                            : search.total_count}
                                                    </strong>
                                                </div>
                                                <div
                                                    className={`list__col ${
                                                        !search.is_processing && search.duplicates > 0 ? 'red' : ''
                                                    }`}
                                                >
                                                    {
                                                        // eslint-disable-next-line no-nested-ternary
                                                        search.is_processing
                                                            ? 'NA'
                                                            : search.duplicates > 0
                                                            ? search.duplicates
                                                            : 0
                                                    }
                                                </div>
                                                <div className="list__col col-overflow">
                                                    {search.list_id || !search.is_salesnav ? (
                                                        <Tooltip
                                                            wrap
                                                            text={tr(
                                                                'The match is only available for a Sales Navigator search'
                                                            )}
                                                            direction="s"
                                                        >
                                                            NA
                                                        </Tooltip>
                                                    ) : (
                                                        search.match_count
                                                    )}
                                                </div>
                                                <div className="list__col col-date">
                                                    {moment(search.created_at).format('LLL')}
                                                </div>
                                                <div
                                                    className={`list__col col-overflow ${
                                                        search.is_enrich ? 'blue bold' : ''
                                                    }`}
                                                >
                                                    {
                                                        // eslint-disable-next-line no-nested-ternary
                                                        search.is_enrich ? (
                                                            search.mail_count
                                                        ) : search.is_enrich_processing ? (
                                                            <span className="enrich-process">
                                                                <Icon size={14} isProcessing icon="cog-alt" />
                                                                <span>{tr('Enriching')}</span>
                                                            </span>
                                                        ) : (
                                                            tr('To enrich')
                                                        )
                                                    }
                                                </div>

                                                <div
                                                    className={`list__col col-overflow ${
                                                        search.is_processing ? 'col-importing' : 'col-imported'
                                                    }`}
                                                >
                                                    {searchStatus(search)}
                                                </div>
                                                <Modal
                                                    ref={editModalRef}
                                                    validateCb={() => {
                                                        const newSearch = { ...editSearch };
                                                        newSearch.name = name;
                                                        actions.updateSearch(editSearch.id, newSearch);
                                                        setEditSearch(null);
                                                    }}
                                                    title={tr('Edit list name')}
                                                    labelValidate={tr('Save')}
                                                >
                                                    <Input
                                                        placeholder={tr('List name')}
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                </Modal>
                                                <div className="list__col list__col--action">
                                                    {search.name !== 'Individual scrapes' && (
                                                        <Dropdown
                                                            iconRight
                                                            btnFaceIconSize={20}
                                                            btnFaceIcon="menu"
                                                            btnFace="link"
                                                            onClick={(e) => e.stopPropagation()}
                                                            onDoubleClick={(e) => e.stopPropagation()}
                                                        >
                                                            <Dropdown.Menu direction="w">
                                                                <Dropdown.ConfirmButtonItem
                                                                    label={tr('Find emails')}
                                                                    placement="left-center"
                                                                    disabled={
                                                                        user?.team?.email_credits <
                                                                        0.7 * search?.total_count
                                                                    }
                                                                    isLoading={search.is_enrich_processing}
                                                                    confirmLabel={tr(
                                                                        'When the email enrichment process is initiated, it cannot be stopped. It will try to find the email addresses of all the leads in the list which are not already enriched. Do you want to continue?'
                                                                    )}
                                                                    onConfirm={() => {
                                                                        actions.enrich(search);
                                                                    }}
                                                                />
                                                                <Dropdown.ButtonItem
                                                                    label={tr('Rename')}
                                                                    placement="left-center"
                                                                    onClick={() => {
                                                                        setName(search.name);
                                                                        setEditSearch(search);
                                                                        editModalRef.current.open();
                                                                    }}
                                                                />
                                                                {search.is_processing && (
                                                                    <>
                                                                        <Dropdown.ButtonItem
                                                                            label={
                                                                                search.is_paused
                                                                                    ? tr('Resume import')
                                                                                    : tr('Pause import')
                                                                            }
                                                                            placement="left-center"
                                                                            onClick={() => {
                                                                                const newSearch = { ...search };
                                                                                newSearch.is_paused = !search.is_paused;
                                                                                actions.updateSearch(
                                                                                    search.id,
                                                                                    newSearch
                                                                                );
                                                                            }}
                                                                        />
                                                                        <Dropdown.ConfirmButtonItem
                                                                            label={tr('Stop import')}
                                                                            placement="left-center"
                                                                            confirmLabel={tr(
                                                                                'Beware, once stopped, the import cannot be resumed.'
                                                                            )}
                                                                            onConfirm={() =>
                                                                                actions.stopSearch(search.id)
                                                                            }
                                                                        />
                                                                    </>
                                                                )}
                                                                <Dropdown.ButtonItem
                                                                    label={
                                                                        search.is_archived
                                                                            ? tr('Unarchive list')
                                                                            : tr('Archive list')
                                                                    }
                                                                    placement="left-center"
                                                                    onClick={() => {
                                                                        const newSearch = { ...search };
                                                                        newSearch.is_archived = !search.is_archived;
                                                                        actions.updateSearch(search.id, newSearch);
                                                                    }}
                                                                />
                                                                <Dropdown.ConfirmButtonItem
                                                                    label={tr('Delete list')}
                                                                    placement="left-center"
                                                                    confirmLabel={tr(
                                                                        'Do you confirm you want to delete this search?'
                                                                    )}
                                                                    onConfirm={() => actions.deleteSearch(search)}
                                                                />
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </InfiniteScroll>
                    ) : (
                        <>
                            {!loading && user?.has_sales_nav && !filterArchive && !filterSearch && (
                                <div className="empty">
                                    <h1>{tr('Start importing Sales Navigator Members to Kanbox')}</h1>
                                    <img
                                        className="empty-img"
                                        src={scrapeImg}
                                        alt={tr('Start Importing Sales Navigator Members to Kanbox')}
                                    />
                                    <a
                                        href="https://www.linkedin.com/sales/search/people"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="btn btn-large btn--primary"
                                    >
                                        {tr('Start Importing')}
                                    </a>
                                </div>
                            )}
                            {!loading && !user?.has_sales_nav && !filterArchive && !filterSearch && (
                                <div className="empty">
                                    <h1>{tr('Start Importing LinkedIn Members to Kanbox')}</h1>
                                    <img
                                        className="empty-img"
                                        src={scrapeImg}
                                        alt={tr('Start Importing LinkedIn Members to Kanbox')}
                                    />
                                    <a
                                        href="https://www.linkedin.com/search/results/people"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="btn btn-large btn--primary"
                                    >
                                        {tr('Start Importing')}
                                    </a>
                                </div>
                            )}
                            {!loading && (filterArchive || filterSearch) && (
                                <div className="empty">
                                    <h1>{tr('No results')}</h1>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
export default Searches;
