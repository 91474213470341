import { useEffect, useState, useRef } from 'react';
import { useRecoilValue, useRecoilState, useResetRecoilState } from 'recoil';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import isEqual from 'lodash.isequal';
import { tr } from '../../common/locale';
import {
    campaignsState,
    campaignState,
    tasksState,
    launchingState,
    selectedCampaignState,
    sourcecountState,
    statsState,
} from '../../_states/campaign.states';
import { boardsState } from '../../_states/lnusers.states';
import { userState } from '../../_states/user.states';
import useCampaignActions from '../../_actions/campaign.actions';
import useUserActions from '../../_actions/user.actions';
import utils from '../../common/utils';
import Button from '../../components/Button';
import ConfirmButton from '../../components/ConfirmButton';
import Input from '../../components/Input';
import TooltipPopover from '../../components/TooltipPopover';
import Tooltip from '../../components/Tooltip';
import Icon from '../../components/Icon';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import Switch from '../../components/Switch';
import CampaignRecipients from './CampaignRecipients';
import CampaignVisit from './CampaignVisit';
import CampaignFollow from './CampaignFollow';
import CampaignLike from './CampaignLike';
import CampaignConnections from './CampaignConnections';
import CampaignTasks from './CampaignTasks';
import CampaignStats from './CampaignStats';
import CampaignMessage from './CampaignMessage';
import CampaignPipeline from './CampaignPipeline';
import CampaignHeader from './CampaignHeader';

function Campaign() {
    const actions = useCampaignActions();
    const userActions = useUserActions();
    const campaigns = useRecoilValue(campaignsState);
    const boards = useRecoilValue(boardsState);
    const launching = useRecoilValue(launchingState);
    const [sourceCount, setSourceCount] = useRecoilState(sourcecountState);
    const user = useRecoilValue(userState);
    const { campaignId } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [campaign, setCampaign] = useRecoilState(campaignState);
    const [selectedCampaign, setSelectedCampaign] = useRecoilState(selectedCampaignState);
    const mainParam = searchParams.get('main') || 'home';
    const resetCampaign = useResetRecoilState(campaignState);
    const resetSelectedCampaign = useResetRecoilState(selectedCampaignState);
    const resetStats = useResetRecoilState(statsState);
    const resetTasks = useResetRecoilState(tasksState);
    const [step, setStep] = useState('pipeline');
    const [cbAlertSave, setCbAlertSave] = useState({ cb: null });
    const tourRef = useRef();
    const inputNameRef = useRef();
    const alertSaveRef = useRef();
    const isEqualCampaign = isEqual(selectedCampaign, campaign);
    const addParam = (searchParamsParam, param, value) => {
        const newParams = {};
        searchParamsParam.forEach((v, k) => {
            newParams[k] = v;
        });
        newParams[param] = value;
        setSearchParams(newParams);
    };
    const createNewCampaign = (newCampaign) => {
        if (newCampaign) {
            setCampaign(newCampaign);
            setSelectedCampaign(JSON.parse(JSON.stringify(newCampaign)));
        } else {
            resetCampaign();
            resetSelectedCampaign();
        }
        setSourceCount(null);
        if (searchParams.has('subscription')) {
            searchParams.delete('subscription');
            setSearchParams(searchParams);
        }
        addParam(searchParams, 'main', 'home');
        setStep('pipeline');
        if (inputNameRef.current) {
            inputNameRef.current.inputRef.current.focus();
        }
    };
    const checkSourceCount = (camp) => {
        if (camp && camp.total_count) {
            setSourceCount(camp.total_count);
        } else {
            setSourceCount(null);
        }
    };
    const alertSave = (cb) => {
        if (!isEqualCampaign) {
            if (cb) {
                setCbAlertSave({ cb });
            } else {
                alertSaveRef.current.open();
            }
        } else {
            cb();
        }
    };

    useEffect(() => {
        if (alertSaveRef.current) {
            if (cbAlertSave.cb) {
                alertSaveRef.current.open();
            } else {
                alertSaveRef.current.close();
            }
        }
    }, [cbAlertSave]);

    useEffect(() => {
        if (campaignId) {
            console.log('campaignId1', campaignId, campaigns);
            if (campaignId === 'new') {
                let current = null;
                let newCampaign = null;
                if (searchParams.get('copy') && campaigns) {
                    current = campaigns.find((camp) => camp.id === parseInt(searchParams.get('copy'), 10));
                }
                if (current) {
                    newCampaign = JSON.parse(JSON.stringify(campaign));
                    newCampaign.recipient_from_list_id = null;
                    newCampaign.id = null;
                    newCampaign.recipient_from_list_filter = null;
                    newCampaign.recipient_from_label_id = null;
                    newCampaign.board_name = '';
                    newCampaign.name = '';
                    newCampaign.board = null;
                    newCampaign.status = null;
                }
                createNewCampaign(newCampaign);
            } else if (campaigns) {
                console.log('campaignId', campaignId, campaigns);
                const current = campaigns.find((camp) => camp.id === parseInt(campaignId, 10));
                if (!current) {
                    console.log('nav1');
                    navigate('/campaigns');
                    return;
                }
                setSelectedCampaign(JSON.parse(JSON.stringify(current)));
                setCampaign(current);
                checkSourceCount(current);
                resetTasks();

                resetStats();
                if (current.id) {
                    actions.getStats(current.id);
                }
            }
        }
    }, [campaignId, campaigns]);

    useEffect(() => {
        if (utils.automationsHasAccess(user) && user.tours && user.tours.indexOf('campaigns') === -1) {
            tourRef.current.open();
        }
    }, [user]);

    useEffect(() => {
        actions.get();
    }, []);
    useEffect(() => {
        checkSourceCount(campaign);
        if (campaign && campaign.id) {
            actions.refreshStatsTasks();
        }
    }, [campaign]);

    useEffect(() => {
        if (!campaignId && campaigns && campaigns.length > 0) {
            const clonedCampaigns = actions.sortCampaigns();
            resetStats();
            if (!campaignId && clonedCampaigns?.length > 0) {
                console.log('nav2', clonedCampaigns[0].id);
                navigate(`/campaigns/${clonedCampaigns[0].id}`);
            }
        }
    }, [campaigns]);

    const hasRecipients = () =>
        campaign &&
        ((campaign.recipient_from === 'leads' && campaign.recipient_from_list_id) || campaign.recipient_from_label_id);

    const getActionError = (currentStep) => {
        if (step === currentStep || !campaign) {
            return null;
        }

        let error = null;
        const noAction =
            !campaign.action_visit &&
            !campaign.action_follow &&
            !campaign.action_like &&
            !campaign.action_connect &&
            !campaign.action_message_1;
        if (currentStep === 'all' && noAction) {
            error = tr('No action defined');
        }
        if ((currentStep === 'recipients' || currentStep === 'all') && !hasRecipients() && !noAction) {
            error = tr('You must select recipients');
        }
        if ((currentStep === 'visit' || currentStep === 'all') && campaign.action_visit) {
            // nothing
        }
        if ((currentStep === 'follow' || currentStep === 'all') && campaign.action_follow) {
            // nothing
        }
        if ((currentStep === 'like' || currentStep === 'all') && campaign.action_like) {
            // nothing
        }
        if ((currentStep === 'connections' || currentStep === 'all') && campaign.action_connect) {
            // nothing
        }
        if ((currentStep === 'message1' || currentStep === 'all') && campaign.action_message_1) {
            if (!campaign.action_message_1_content) {
                error = tr('You must define a message content');
            }
        }
        if ((currentStep === 'message2' || currentStep === 'all') && campaign.action_message_2) {
            if (!campaign.action_message_2_content) {
                error = tr('You must define a Follow-up message 1 content');
            }
        }
        if ((currentStep === 'message3' || currentStep === 'all') && campaign.action_message_3) {
            if (!campaign.action_message_2_content) {
                error = tr('You must define a Follow-up message 2 content');
            }
        }
        if ((currentStep === 'message4' || currentStep === 'all') && campaign.action_message_4) {
            if (!campaign.action_message_2_content) {
                error = tr('You must define a Follow-up message 3 content');
            }
        }

        if (error) {
            return (
                <TooltipPopover text={error}>
                    <Icon size={16} icon="info" fill fillColor="#ff0055" />
                </TooltipPopover>
            );
        }

        return null;
    };
    const getStepIndex = (currentStep) => {
        const actionsList = {
            action_visit: 1,
            action_follow: 1,
            action_like: 1,
            action_connect: campaign.action_connect_ignore_delete ? 4 : 3,
            action_message_1: 2,
            action_message_2: 2,
            action_message_3: 2,
            action_message_4: 2,
        };
        let index = 1;
        let result = 1;
        Object.keys(actionsList).forEach((act) => {
            if (currentStep === act) {
                result = index;
            }
            if (campaign[act]) {
                index += actionsList[act];
            }

            return index;
        });
        return result;
    };
    const board = boards?.find((elem) => elem.id === campaign?.board);
    if (!user || !campaign) {
        return (
            <div className="board__empty">
                <Loader isChat text="Loading Campaigns..." />
            </div>
        );
    }

    const playingCampaigns = campaigns?.filter((current) => current.status === 'PLAYING');
    const queuedCampaigns = campaigns?.filter((current) => current.status === 'QUEUED');
    const draftCampaigns = campaigns?.filter((current) => current.status === 'DRAFT');
    const finishedCampaigns = campaigns?.filter((current) => current.status === 'FINISHED');
    const archivedCampaigns = campaigns?.filter((current) => current.status === 'ARCHIVED');

    return (
        <div className="content campaigns" key={campaign.id || 'new'}>
            <Modal
                className="welcome-campaigns"
                labelValidate={tr('Close')}
                ref={tourRef}
                closeCb={() => {
                    userActions.updateTour('campaigns');
                }}
                title={tr('Automated campaigns')}
            >
                <div className="welcome-campaigns__image">
                    <Icon isEmpty icon="empty-automations-beta" size={260} />
                </div>
                <div className="welcome-campaigns__message">
                    <p className="warning">
                        {tr(
                            'Campaigns run in the browser. Therefore, it is important not to close your browser while they are running. If you close your browser, they will pause and resume when it is reopened.'
                        )}
                    </p>
                    <p>{tr("Campaigns are scheduled according to the settings you've defined:")}</p>
                    <ul className="welcome-campaigns__actions">
                        <li>
                            <Link className="btn btn--white btn--bordered" to="/settings/schedule">
                                {tr('Modify your schedule')}
                            </Link>
                        </li>
                        <li>
                            <Link className="btn btn--white btn--bordered" to="/settings/quotas">
                                {tr('Modify your quotas')}
                            </Link>
                        </li>
                    </ul>
                </div>
            </Modal>
            <Modal
                ref={alertSaveRef}
                labelValidate={tr('Save')}
                labelCancel={campaign?.id ? tr('Discard changes') : tr('Cancel campaign')}
                validateCb={() => {
                    actions.save(campaign);
                    setCbAlertSave({ cb: null });
                }}
                closeCb={() => {
                    if (cbAlertSave.cb) {
                        cbAlertSave.cb();
                    }
                }}
                title={tr('Changes are being made')}
            >
                {campaign?.id && (
                    <p>{tr("You've started to do changes to the current campaign. Do you want to save them first?")}</p>
                )}
                {!campaign?.id && <p>{tr("You've started to create a new campaign. Do you want to save it first?")}</p>}
            </Modal>

            <div className="content__sidebar">
                <div className="content__sidebar__header">
                    <div className="content__sidebar__header__title">
                        <h1 className="title">{tr('Campaigns')}</h1>
                        <Button
                            label={tr('+ New')}
                            onClick={() => {
                                alertSave(() => navigate('/campaigns/new'));
                            }}
                        />
                    </div>
                </div>
                <div className="content__sidebar__body">
                    <div className="content__sidebar__body__section">
                        <div className="content__sidebar__body__section__header">
                            <h5 className="title">{tr('Settings')}</h5>
                        </div>
                        <ul>
                            <li>
                                <Button
                                    icon="clock"
                                    iconSize={20}
                                    type="secondary"
                                    label={tr('Campaign Scheduler')}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        alertSave(() => navigate('/settings/schedule'));
                                    }}
                                />
                            </li>
                        </ul>
                    </div>

                    <div className="content__sidebar__body__section">
                        <div className="content__sidebar__body__section__header">
                            <h5 className="title">
                                {tr('Playing')}{' '}
                                <TooltipPopover
                                    text={tr(
                                        'A maximum of 3 campaigns are played simultaneously. The others are placed in the queue awaiting the completion of one of them.'
                                    )}
                                >
                                    <Icon size={16} icon="info" fill fillColor="#3ec2ff" />
                                </TooltipPopover>
                            </h5>
                        </div>
                        <ul className="content__sidebar__body__section__filters">
                            {playingCampaigns?.map((current) => (
                                <li key={current.id}>
                                    <Button
                                        icon="play-square"
                                        iconSize={20}
                                        active={campaign?.id === current.id}
                                        type="secondary"
                                        label={current.name}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            alertSave(() => navigate(`/campaigns/${current.id}`));
                                        }}
                                    />
                                </li>
                            ))}
                            {playingCampaigns?.length === 0 && (
                                <li>
                                    <span className="campaign__empty">{tr('No playing campaign')}</span>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="content__sidebar__body__section">
                        <div className="content__sidebar__body__section__header">
                            <h5 className="title">{tr('Queued')}</h5>
                        </div>
                        <ul className="content__sidebar__body__section__filters">
                            {queuedCampaigns?.map((current) => (
                                <li key={current.id}>
                                    <Button
                                        icon="play-square"
                                        iconSize={20}
                                        active={campaign.id === current.id}
                                        type="secondary"
                                        label={current.name}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            alertSave(() => navigate(`/campaigns/${current.id}`));
                                        }}
                                    />
                                </li>
                            ))}
                            {queuedCampaigns?.length === 0 && (
                                <li>
                                    <span className="campaign__empty">{tr('No queued campaign')}</span>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="content__sidebar__body__section">
                        <div className="content__sidebar__body__section__header">
                            <h5 className="title">{tr('Draft / Paused')}</h5>
                        </div>
                        <ul className="content__sidebar__body__section__filters">
                            {draftCampaigns?.map((current) => (
                                <li key={current.id}>
                                    <Button
                                        icon="play-square"
                                        iconSize={20}
                                        active={campaign.id === current.id}
                                        type="secondary"
                                        label={current.name}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            alertSave(() => navigate(`/campaigns/${current.id}`));
                                        }}
                                    />
                                </li>
                            ))}
                            {draftCampaigns?.length === 0 && (
                                <li>
                                    <span className="campaign__empty">{tr('No draft campaign')}</span>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="content__sidebar__body__section">
                        <div className="content__sidebar__body__section__header">
                            <h5 className="title">{tr('Finished')}</h5>
                        </div>
                        <ul className="content__sidebar__body__section__filters">
                            {finishedCampaigns?.map((current) => (
                                <li key={current.id}>
                                    <Button
                                        icon="play-square"
                                        iconSize={20}
                                        active={campaign.id === current.id}
                                        type="secondary"
                                        label={current.name}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            alertSave(() => navigate(`/campaigns/${current.id}`));
                                        }}
                                    />
                                </li>
                            ))}
                            {finishedCampaigns?.length === 0 && (
                                <li>
                                    <span className="campaign__empty">{tr('No finished campaign')}</span>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="content__sidebar__body__section">
                        <div className="content__sidebar__body__section__header">
                            <h5 className="title">{tr('Archived')}</h5>
                        </div>
                        <ul className="content__sidebar__body__section__filters">
                            {archivedCampaigns?.map((current) => (
                                <li key={current.id}>
                                    <Button
                                        icon="play-square"
                                        iconSize={20}
                                        active={campaign.id === current.id}
                                        type="secondary"
                                        label={current.name}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            alertSave(() => navigate(`/campaigns/${current.id}`));
                                        }}
                                    />
                                </li>
                            ))}
                            {archivedCampaigns?.length === 0 && (
                                <li>
                                    <span className="campaign__empty">{tr('No archived campaign')}</span>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="content__body">
                {utils.automationsHasAccess(user) && !user?.plan?.has_automations && (
                    <div className="list__filter service-message service-message--info">
                        <div>
                            {tr('You can try automated campaigns until {{date}}.', {
                                date: moment(user?.plan?.trial_date_automations).format('LL'),
                            })}
                            &nbsp;
                            {user?.plan.owner === user.id && (
                                <Link to="/subscription">{tr('Click here to subscribe.')}</Link>
                            )}
                        </div>
                    </div>
                )}
                {!utils.automationsHasAccess(user) && (
                    <div className="service-message service-message--info">
                        <div>
                            {tr('Automated campaigns are not available with your subscription.')}
                            &nbsp;
                            {user?.plan.owner === user.id && (
                                <Link to="/subscription">{tr('Click here to subscribe.')}</Link>
                            )}
                        </div>
                    </div>
                )}

                <div className={`campaign__header ${mainParam === 'home' && 'home'}`}>
                    <Tooltip direction="e" className="campaign__btn" text={tr('Sequences')}>
                        {campaign?.id ? (
                            <Button
                                className={`${mainParam === 'home' && 'active'}`}
                                type="campaign"
                                icon="play-square"
                                iconSize={24}
                                onClick={() => addParam(searchParams, 'main', 'home')}
                            />
                        ) : (
                            <div className="campaign__btn campaign__btn--bordered">
                                <Icon icon="play-square" size={24} />
                            </div>
                        )}
                    </Tooltip>
                    {campaign?.id && (
                        <>
                            <Tooltip direction="e" className="campaign__btn" text={tr('Stats')}>
                                <Button
                                    className={`${mainParam === 'stats' && 'active'}`}
                                    type="campaign"
                                    icon="chart"
                                    iconSize={24}
                                    onClick={() => addParam(searchParams, 'main', 'stats')}
                                />
                            </Tooltip>
                            {campaign.status !== 'ARCHIVED' && (
                                <Tooltip direction="e" className="campaign__btn" text={tr('Scheduled tasks')}>
                                    <Button
                                        className={`${mainParam === 'tasks' && 'active'}`}
                                        type="campaign"
                                        icon="sync"
                                        iconSize={24}
                                        onClick={() => addParam(searchParams, 'main', 'tasks')}
                                    />
                                </Tooltip>
                            )}
                        </>
                    )}
                    <div className="campaign__header__title ellipsis">
                        {campaign && (
                            <Input
                                placeholder={tr('Type in a name')}
                                ref={inputNameRef}
                                isFocusedDefault={campaign?.status !== 'ARCHIVED' && campaign?.status !== 'FINISHED'}
                                disabled={campaign?.status === 'ARCHIVED' || campaign?.status === 'FINISHED'}
                                value={campaign?.name}
                                onChange={(e) => setCampaign({ ...campaign, name: e.target.value })}
                            />
                        )}
                        {campaign?.id && (
                            <div className="campaign__header__title__actions">
                                <Tooltip direction="w" text={tr('Create a copy')}>
                                    <Button
                                        iconLight
                                        type="link"
                                        icon="copy"
                                        iconSize={24}
                                        className=""
                                        onClick={() => alertSave(() => navigate(`/campaigns/new?copy=${campaign?.id}`))}
                                    />
                                </Tooltip>

                                {campaign?.id &&
                                    campaign.status !== 'QUEUED' &&
                                    campaign.status !== 'PLAYING' &&
                                    campaign.status !== 'ARCHIVED' && (
                                        <Tooltip direction="w" text={tr('Archive Campaign')}>
                                            <Button
                                                iconLight
                                                type="link"
                                                icon="archive"
                                                iconSize={24}
                                                disabled={!campaign.id}
                                                onClick={() => actions.archive(campaign.id)}
                                            />
                                        </Tooltip>
                                    )}
                                {campaign.status !== 'PLAYING' && campaign.status !== 'QUEUED' && (
                                    <Tooltip direction="w" text={tr('Delete Campaign')}>
                                        <ConfirmButton
                                            iconLight
                                            type="link"
                                            icon="trash-can"
                                            iconSize={24}
                                            confirmLabel={tr('Are you sure you want to delete this campaign?')}
                                            onConfirm={() => actions.deleteCampaign(campaign.id)}
                                        />
                                    </Tooltip>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="campaign__header__actions">
                        {campaign && campaign?.status !== 'ARCHIVED' && campaign?.status !== 'FINISHED' && (
                            <Button
                                icon="save"
                                title={campaign.board ? tr('Save changes') : tr('Save draft')}
                                label={campaign.board ? tr('Save changes') : tr('Save draft')}
                                disabled={!campaign.name || (selectedCampaign && isEqualCampaign)}
                                onClick={() => actions.save(campaign)}
                            />
                        )}

                        {campaign && campaign?.status === 'DRAFT' && campaign?.board && (
                            <Button
                                type="yellow"
                                icon="stop"
                                label={tr('Finish')}
                                title={tr('Finish')}
                                disabled={!campaign.id}
                                onClick={() => actions.finish(campaign.id)}
                            />
                        )}

                        {campaign &&
                            campaign.status !== 'PLAYING' &&
                            campaign.status !== 'ARCHIVED' &&
                            campaign.status !== 'QUEUED' && (
                                <Button
                                    type="green"
                                    icon="play-square"
                                    label={campaign?.board ? tr('Resume') : tr('Launch')}
                                    title={campaign?.board ? tr('Resume') : tr('Launch')}
                                    isLoading={launching}
                                    disabled={
                                        !campaign.id ||
                                        !!getActionError('all') ||
                                        (selectedCampaign && !isEqual(selectedCampaign, campaign))
                                    }
                                    onClick={() => actions.launch(campaign.id)}
                                />
                            )}
                        {(campaign?.status === 'PLAYING' || campaign?.status === 'QUEUED') && (
                            <Button
                                type="yellow"
                                icon="stop"
                                label={tr('Pause / Stop')}
                                title={tr('Pause / Stop')}
                                disabled={!campaign.id}
                                onClick={() => actions.stop(campaign.id)}
                            />
                        )}
                    </div>
                </div>
                <CampaignHeader campaign={campaign} board={campaign?.board} />
                {mainParam === 'tasks' && <CampaignTasks />}
                {mainParam === 'stats' && <CampaignStats />}
                {mainParam === 'home' && (
                    <div className="content__body campaign">
                        <div className="content">
                            <div className="campaign__sequences">
                                <h2 className="title">
                                    {tr('Sequences')}
                                    <TooltipPopover
                                        text={tr(
                                            'Select recipients, then choose the sequences you wish to add to your campaign by activating the selector to the right of the sequence name. To configure a sequence, click on its name.'
                                        )}
                                    >
                                        <Icon size={16} icon="info" fill fillColor="#3ec2ff" />
                                    </TooltipPopover>

                                    {sourceCount && (
                                        <span className="sequence-count">
                                            {tr('{{count}} recipients', { count: sourceCount })}
                                        </span>
                                    )}
                                </h2>
                                <div
                                    className={`sequence ${step === 'pipeline' ? 'active' : ''}`}
                                    onClick={() => setStep('pipeline')}
                                    onKeyDown={() => setStep('pipeline')}
                                    role="button"
                                    tabIndex={-1}
                                >
                                    <div>{tr('Auto-generated Pipeline')}</div>
                                    <div>{campaign.board_name ? <Icon green size={24} icon="check" /> : null}</div>
                                </div>
                                <div className="campaign__sequences-wrapper">
                                    <div
                                        className={`sequence ${step === 'recipients' ? 'active' : ''}`}
                                        onClick={() => setStep('recipients')}
                                        onKeyDown={() => setStep('recipients')}
                                        role="button"
                                        tabIndex={-1}
                                    >
                                        <div className="sequence-title">
                                            <span>{tr('Recipients')}</span> {getActionError('recipients')}
                                        </div>

                                        {hasRecipients() ? <Icon green size={24} icon="check" /> : null}
                                    </div>
                                    <div
                                        className={`sequence ${step === 'visit' ? 'active' : ''}`}
                                        onClick={() => setStep('visit')}
                                        onKeyDown={() => setStep('visit')}
                                        role="button"
                                        tabIndex={-1}
                                    >
                                        <div className="sequence-title">
                                            <span>{tr('Visit profile')}</span> {getActionError('visit')}
                                        </div>

                                        <div>
                                            <Switch
                                                id="visitProfiles"
                                                disabled={
                                                    campaign?.status === 'ARCHIVED' || campaign?.status === 'FINISHED'
                                                }
                                                checked={campaign.action_visit}
                                                onChange={(val) => setCampaign({ ...campaign, action_visit: val })}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={`sequence ${step === 'follow' ? 'active' : ''}`}
                                        onClick={() => setStep('follow')}
                                        onKeyDown={() => setStep('follow')}
                                        role="button"
                                        tabIndex={-1}
                                    >
                                        <div className="sequence-title">
                                            <span>{tr('Follow member')}</span> {getActionError('follow')}
                                        </div>

                                        <div>
                                            <Switch
                                                id="followProfiles"
                                                disabled={
                                                    campaign?.status === 'ARCHIVED' || campaign?.status === 'FINISHED'
                                                }
                                                checked={campaign.action_follow}
                                                onChange={(val) => setCampaign({ ...campaign, action_follow: val })}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={`sequence ${step === 'like' ? 'active' : ''}`}
                                        onClick={() => setStep('like')}
                                        onKeyDown={() => setStep('like')}
                                        role="button"
                                        tabIndex={-1}
                                    >
                                        <div className="sequence-title">
                                            <span>{tr("Like member's post")}</span> {getActionError('like')}
                                        </div>

                                        <div>
                                            <Switch
                                                id="likeProfiles"
                                                disabled={
                                                    campaign?.status === 'ARCHIVED' || campaign?.status === 'FINISHED'
                                                }
                                                checked={campaign.action_like}
                                                onChange={(val) => setCampaign({ ...campaign, action_like: val })}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={`sequence ${step === 'connections' ? 'active' : ''}`}
                                        onClick={() => setStep('connections')}
                                        onKeyDown={() => setStep('connections')}
                                        role="button"
                                        tabIndex={-1}
                                    >
                                        <div className="sequence-title">
                                            <span>{tr('Connection request')}</span> {getActionError('connections')}
                                        </div>

                                        <div>
                                            <Switch
                                                id="connections"
                                                checked={campaign.action_connect}
                                                disabled={
                                                    campaign?.status === 'ARCHIVED' || campaign?.status === 'FINISHED'
                                                }
                                                onChange={(val) => setCampaign({ ...campaign, action_connect: val })}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={`sequence ${step === 'message1' ? 'active' : ''} ${
                                            getActionError('message1') !== null ? 'incomplete' : ''
                                        }`}
                                        onClick={() => setStep('message1')}
                                        onKeyDown={() => setStep('message1')}
                                        role="button"
                                        tabIndex={-1}
                                    >
                                        <div className="sequence-title">
                                            <span>{tr('Send message')}</span> {getActionError('message1')}
                                        </div>

                                        <div>
                                            <Switch
                                                id="message1"
                                                checked={campaign.action_message_1}
                                                disabled={
                                                    campaign?.status === 'ARCHIVED' || campaign?.status === 'FINISHED'
                                                }
                                                onChange={(val) => {
                                                    const newC = { ...campaign };
                                                    newC.action_message_1 = val;
                                                    if (!val) {
                                                        newC.action_message_4 = val;
                                                        newC.action_message_3 = val;
                                                        newC.action_message_2 = val;
                                                    }
                                                    setCampaign(newC);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={`sequence ${step === 'message2' ? 'active' : ''}`}
                                        onClick={() => setStep('message2')}
                                        onKeyDown={() => setStep('message2')}
                                        role="button"
                                        tabIndex={-1}
                                    >
                                        <div className="sequence-title">
                                            <span>{tr('Follow-up message 1')}</span> {getActionError('message2')}
                                        </div>

                                        <div>
                                            <Switch
                                                id="message2"
                                                checked={campaign.action_message_2}
                                                disabled={
                                                    !campaign.action_message_1 ||
                                                    campaign?.status === 'ARCHIVED' ||
                                                    campaign?.status === 'FINISHED'
                                                }
                                                onChange={(val) => {
                                                    const newC = { ...campaign };
                                                    newC.action_message_2 = val;
                                                    if (!val) {
                                                        newC.action_message_4 = val;
                                                        newC.action_message_3 = val;
                                                    }
                                                    setCampaign(newC);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={`sequence ${step === 'message3' ? 'active' : ''}`}
                                        onClick={() => setStep('message3')}
                                        onKeyDown={() => setStep('message3')}
                                        role="button"
                                        tabIndex={-1}
                                    >
                                        <div className="sequence-title">
                                            <span>{tr('Follow-up message 2')}</span> {getActionError('message3')}
                                        </div>

                                        <div>
                                            <Switch
                                                id="message3"
                                                disabled={
                                                    !campaign.action_message_2 ||
                                                    campaign?.status === 'ARCHIVED' ||
                                                    campaign?.status === 'FINISHED'
                                                }
                                                checked={campaign.action_message_3}
                                                onChange={(val) => {
                                                    const newC = { ...campaign };
                                                    newC.action_message_3 = val;
                                                    if (!val) {
                                                        newC.action_message_4 = val;
                                                    }
                                                    setCampaign(newC);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={`sequence ${step === 'message4' ? 'active' : ''} ${
                                            getActionError('message4') !== null ? 'incomplete' : ''
                                        }`}
                                        onClick={() => setStep('message4')}
                                        onKeyDown={() => setStep('message4')}
                                        role="button"
                                        tabIndex={-1}
                                    >
                                        <div className="sequence-title">
                                            <span>{tr('Follow-up message 3')}</span> {getActionError('message4')}
                                        </div>

                                        <div>
                                            <Switch
                                                id="message4"
                                                checked={campaign.action_message_4}
                                                disabled={
                                                    !campaign.action_message_3 ||
                                                    campaign?.status === 'ARCHIVED' ||
                                                    campaign?.status === 'FINISHED'
                                                }
                                                onChange={(val) => {
                                                    setCampaign({ ...campaign, action_message_4: val });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content__body campaign__config">
                                {step === 'recipients' && <CampaignRecipients board={board} />}
                                {step === 'visit' && <CampaignVisit getStepIndex={getStepIndex} board={board} />}
                                {step === 'follow' && <CampaignFollow getStepIndex={getStepIndex} board={board} />}
                                {step === 'like' && <CampaignLike getStepIndex={getStepIndex} board={board} />}
                                {step === 'connections' && (
                                    <CampaignConnections getStepIndex={getStepIndex} board={board} />
                                )}
                                {step === 'message1' && (
                                    <CampaignMessage getStepIndex={getStepIndex} mess={1} board={board} />
                                )}
                                {step === 'message2' && (
                                    <CampaignMessage getStepIndex={getStepIndex} mess={2} board={board} />
                                )}
                                {step === 'message3' && (
                                    <CampaignMessage getStepIndex={getStepIndex} mess={3} board={board} />
                                )}
                                {step === 'message4' && (
                                    <CampaignMessage getStepIndex={getStepIndex} mess={4} board={board} />
                                )}
                                {step === 'pipeline' && <CampaignPipeline getStepIndex={getStepIndex} board={board} />}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
export default Campaign;
